import Icon from "components/icon/icon"
import { useChatroom } from "stores/chatroom"
import { useRooms } from "stores/rooms"
const OpenChat = () => {
  const { current } = useRooms()
  //current.clientCounter + 1 }
  const { displayChatroom, setDisplayChatroom } = useChatroom()
  return (
    <div className="controlButton d-flex justify-content-center align-items-center" onClick={()=>{setDisplayChatroom(!displayChatroom)}}>
      <Icon className="onlyMobile" name="chat" size={20}/>
      <p className="onlyDesktop">{displayChatroom ? "Close Chat" : "Open Chat"}</p>
    </div>
  )
}

export default OpenChat