import Select from "react-select"
import {useEffect, useState} from "react";
import OwnStream from "./OwnStream";
import {MediaDeviceType, MediaStreamTrackType} from "../../../../hardcoded/streamHelper";
import Button from "./Button";
import useDevices from "stores/devices";

const DeviceSelectors = () => {
  const { getDeviceList, requestDevice, lastVideoDeviceId, lastAudioDeviceId, lastSinkDeviceId, saveSettings, setMediaTrack } = useDevices()
  const [ videoDevices, setVideoDevices ] = useState(null);
  const [ audioInputDevices, setAudioInputDevices ] = useState(null);
  // todo: consult if we should simplify for mobile and complicate it for desktop peers
  // todo: desktop users should be able to select any of their output devices
  // todo: while mobile users should be able to switch between earpice, speaker, bluetooth?, etc
  const [ audioOutputDevices, setAudioOutputDevices ] = useState(null);

  const [ selfVideoTrack, setSelfVideoTrack ] = useState(null);
  const [ selfAudioTrack, setSelfAudioTrack ] = useState(null);
  const [ selfStream, setSelfStream ] = useState(new MediaStream);

  const [ selectedAudioDevice, setSelectedAudioDevice ] = useState(lastAudioDeviceId);
  const [ selectedVideoDevice, setSelectedVideoDevice ] = useState(lastVideoDeviceId);
  const [ selectedSinkDevice, setSelectedSinkDevice ] = useState(lastSinkDeviceId);

  useEffect(() => {
    async function getDevices() {
      try {
        const deviceList = await getDeviceList()
        console.log("device list:",deviceList)
        setVideoDevices(deviceList.video.map(device => ({
          value: device.deviceId,
          label: `Video: ${device.label}`
        })))
        setAudioInputDevices(deviceList.input.map(device => ({
          value: device.deviceId,
          label: `Input: ${device.label}`
        })))
        setAudioOutputDevices(deviceList.output.map(device => ({
          value: device.deviceId,
          label: `Output: ${device.label}`
        })))
      }catch (e) {
        console.log('DeviceManager: could not properly load devices list, error: ', e)
        return false
      }
      return true
    }
    getDevices().then(() => {
      console.log('DeviceManager: loaded devices list ')
    })
  }, [getDeviceList])

  const defaultValues = {
    input: "Input Device",
    video: "Video Device",
    output: "Output Devices"
  }
  const selectDefaults = {
    styles: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 0,
        borderColor: state.isFocused ? 'white' : 'white',
        width: "270px",
        backgroundColor: "#f1f2f7",
        boxShadow: state.isFocused ? 'none' : 'none',
        outline: state.isFocused ? 'none' : 'none',
      }),
    },
    components: {
      IndicatorSeparator: () => null
    }
  }
  const changeInput = async (e, kind = '') => {
    let deviceTrack = null
    if (e.value && e.value.length !== '') {
      deviceTrack = await requestDevice(kind, e.value)
      if (!deviceTrack) {
        alert(`Cannot select ${e.label}.`)
        return
      }
    }

    if (kind === MediaDeviceType.VideoInput) {
      selfStream.getVideoTracks().forEach(track => selfStream.removeTrack(track))
      if (deviceTrack) {
        selfStream.addTrack(deviceTrack)
      }
      setSelectedVideoDevice(e.value)
      setSelfVideoTrack(deviceTrack)
    } else if (kind === MediaDeviceType.AudioInput) {
      selfStream.getAudioTracks().forEach(track => selfStream.removeTrack(track))
      if (deviceTrack) {
        selfStream.addTrack(deviceTrack)
      }
      setSelectedAudioDevice(e.value)
      setSelfAudioTrack(deviceTrack)
    } else if (kind === MediaDeviceType.AudioOutput) {
      setSelectedSinkDevice(e.value)
      return
    }
    setSelfStream(selfStream)
  }
  const onApply = async (e) => {
    if (selfStream) {
      const partial = { devicesAreReady: true }
      if (selectedAudioDevice !== lastAudioDeviceId) {
        setMediaTrack(selfAudioTrack, MediaStreamTrackType.Audio)
        partial.lastAudioDeviceId = selectedAudioDevice
      }
      if (selectedVideoDevice !== lastVideoDeviceId) {
        setMediaTrack(selfVideoTrack, MediaStreamTrackType.Video)
        partial.lastVideoDeviceId = selectedVideoDevice
      }
      if (selectedSinkDevice !== lastSinkDeviceId) {
        partial.lastSinkDeviceId = selectedSinkDevice
      }
      useDevices.setState(partial)
      saveSettings()
    }
  }
  //todo: set select value on selected device from devices state
  return (
    <>
      {
        videoDevices ? (
          <>
          <Select
            {...selectDefaults}
            isDisabled={!videoDevices.length}
            options={[...videoDevices, {label: "No Video", value: ""}]}
            defaultValue={videoDevices.filter(device => device.value === lastVideoDeviceId) || [{label: "No Video", value: ""}]}
            className={'react-select'}
            isSearchable={false}
            placeholder={videoDevices.length ? defaultValues.video : 'No video'}
            onChange={e=>changeInput(e, MediaDeviceType.VideoInput)}/>
          {
            selfStream.getVideoTracks().length ? (
              <OwnStream stream={selfStream}/>
            ) : (
              <></>
            )
          }
          </>
        ) : (
          <span>Detecting cameras...</span>
        )
      }
      {
        audioInputDevices ? (
          <Select
            {...selectDefaults}
            placeholder={audioInputDevices.length ? defaultValues.input : 'No microphones detected'}
            isDisabled={!audioInputDevices.length}
            isSearchable={false}
            className={'react-select'}
            options={[...audioInputDevices, {label: "Stop Microphone", value: ""}]}
            defaultValue={audioInputDevices.filter(device => device.value === lastAudioDeviceId ) || [{label: "Stop Microphone", value: ""}]}
            onChange={e=>changeInput(e, MediaDeviceType.AudioInput)}/>
        ) : (
          <span>Detecting microphones...</span>
        )
      }
      {/*{*/}
      {/*  audioOutputDevices ? (*/}
      {/*    <Select*/}
      {/*      {...selectDefaults}*/}
      {/*      placeholder={audioOutputDevices.length ? defaultValues.output : 'No Speaker Detected'}*/}
      {/*      isDisabled={!audioOutputDevices.length}*/}
      {/*      className={'react-select'}*/}
      {/*      isSearchable={false}*/}
      {/*      options={audioOutputDevices}*/}
      {/*      defaultValue={audioOutputDevices.filter(device => device.value === lastSinkDeviceId ) || []}*/}
      {/*      onChange={e=>changeInput(e, MediaDeviceType.AudioOutput)}/>*/}
      {/*  ) : (*/}
      {/*    <span>Detecting speakers...</span>*/}
      {/*  )*/}
      {/*}*/}
      <Button buttonStyle={'wideCyan'} style={{marginLeft: "5px", height:'48px'}} text={'Apply'} onClick={onApply}/>
    </>
  )
}
export default DeviceSelectors
