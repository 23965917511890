import "react-phone-input-2/lib/style.css";
import Input from "components/input/input"
import Authentication from "../authentication"
import Window from "../window"
import { useState } from "react"
import Checkbox from "components/checkbox"
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Recovery = () => {
  const navigate = useNavigate()
  const { recovery, isAuthenticated } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [email, setEmail] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setEmail("")
    setTermsAgreed(false)
  }
  const attemptPasswordRecovery = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const result = await recovery({
      email: email.toLowerCase(),
    })
    console.log("Result: ", result)
    if (result === true) {
      resetState()
      setSuccess(`We've sent an email to ${email}, follow the instructions there to continue.`)
    } else {
      setError("Something went wrong!")
      setIsLoading(false)
      setTimeout(() => {
        setError("")
      }, 2000)
    }
  }
  return (
    <Authentication>
      <Window
        title="Password Recovery"
        message="Requesting Recovery"
        isLoading={isLoading}
        minHeight="235px"
        error={error}
        success={success}>
        <h1 className="text-center">Password Recovery</h1>
        <form onSubmit={attemptPasswordRecovery}>
          <Input
            name="email"
            label="Email"
            type="email"
            value={email}
            autoComplete="username"
            required
            onChange={(e) => setEmail(e.target.value)} />
          <Checkbox
            value={termsAgreed}
            onClick={() => setTermsAgreed(!termsAgreed)}
            required
            label="I agree to the"
            linkLabel="Booom Disclaimer"
            linkTo="/disclaimer" />
          <input
            className={
              `MainButton ${email && email.length >= 3 ?
                "" : "disabled"
              }`}
            type="submit"
            required />
        </form>
      </Window>
    </Authentication>
  )
}
export default Recovery