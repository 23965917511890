import Icon from "components/icon/icon"
import "./maintenance.scss"
import Logo from "components/logo"
const Maintenance = () => {
  return (
    <div className="maintenanceContainer">
      <div className="merge">
        <Icon name="maintenance" className="robot"/>
        <div className="logoContainer">
          <Logo />
        </div>
      </div>
      <br />
      <h1>Under Maintenance</h1>
      <br />
      <div className="d-flex flex-column">
        <p>We're working on improving your overall experience.</p>
        <p>Please check back shortly.</p>
      </div>
    </div>
  )
}

export default Maintenance