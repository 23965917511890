import { create } from "zustand"

function storeSession(object) {
  window.localStorage.setItem("lastRoom", JSON.stringify(object))
}
function retrieveSession () {
  const JSONSession = window.localStorage.getItem("lastRoom")
  if(JSONSession) {
    return JSON.parse(JSONSession)
  }
  return false
}
export const useRooms = create((set, get) => ({
  current: null,
  chatIsOpen: false,
  increaseClientCounter: () => {
    const { current } = get()
    const updatedCurrent = {
      ...current,
      clientCounter: current.clientCounter + 1
    }
    set({
      current: updatedCurrent
    })
  },
  decreaseClientCounter: () => {
    const { current } = get()
    const updatedCurrent = {
      ...current,
      clientCounter: current.clientCounter - 1
    }
    set({
      current: updatedCurrent
    })
  },
  createRoom: async ({
    name,
    passwordProtected,
    password,
    autoAccept,
    autoSwitch,
  }) => {
    try{
      const req = {
        name,
        passwordProtected,
        password,
      }
      if(autoAccept) {
        req.autoAccept = true
      }
      if(autoSwitch) {
        req.autoSwitch = true
      }
      const response = await window.$comms.request("createTemporaryRoom", req)
      return response.uuid
    } catch (e) {
      console.dir(e)
      console.log("Error couldn't create room")
      return false
    }
  },
  queryRoom: async ({roomId}) => {
    try {
      const response = await window.$comms.request("simulateJoinTemporaryRoom", {roomId})
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  },
  joinRoom: async ({roomId, name}) => {
    try {
      const response = await window.$comms.request("joinTemporaryRoom", {roomId, name})
      set({
        current: {
          name: response.name,
          id: roomId,
          passwordProtected: response.passwordProtected,
          secure: response.secure,
          clientCounter: 0
        }
      })
      return true
    } catch (e) {
      set({errorMessage: e.reason})
      return e.reason
    }
  },
  leaveRoom: async({roomId}) => {
    try {
      const response = await window.$comms.request("leaveRoom", {roomId})
    } catch (e) {
      console.log(e)
    }
  },
  clearRoom () {
    const { current, leaveRoom } = get()
    set({
      current: null,
      chatIsOpen: false
    })
    if(current) {
      leaveRoom({roomId: current.id})
    }
  }
}))

