import { create } from 'zustand'
import { usePreferences } from './preferences'
export const useAuthentication = create((set, get) => ({
  isAuthenticated: false,
  user: null,
  login: async (data) => {
    try{
      const response = await window.$comms.request("login", data)
      window.localStorage.setItem("token", response.token)
      const { useToken } = get()
      await useToken(response.token)
      return true
    } catch(e) {
      if(e === "NOT_VERIFIED") {
        return e
      }
      return false
    }
  },
  register: async (details) => {
    try{
      const response = await window.$comms.request("register", details)
      window.localStorage.setItem("token", response.token)
      return true
    } catch (e) {
      if(e.reason === "ALREADY_EXISTS") {
        return "Email already exists!"
      } else if (e.reason === "NOT_VERIFIED") {
        return "Please verify your email."
      } else {
        return "Registration Failed!"
      }
    }
  },
  recovery: async (details) => {
    try {
      const response = await window.$comms.request("recovery", details)
      return true
    } catch (e) {
      return false
    }
  },
  recover: async (details) => {
    try {
      const response = await window.$comms.request("recover", details)
      return true
    } catch (e) {
      if(e.reason) {
        return e.reason
      }
      return false
    }
  },
  resend: async ({email}) => {
    try {
      const response = await window.$comms.request("resend", {email})
      return true
    } catch (e) {
      if(e.reason) {
        return e.reason
      }
      return false
    }
  },
  verify: async (details) => {
    try {
      const response = await window.$comms.request("verify", details)
      window.localStorage.setItem("token", response.token)
      const {useToken} = get()
      await useToken(localStorage.token)
      return true
    } catch (e) {
      if(e.reason) {
        return e.reason
      }
      return false
    }
  },
  useToken: async (token) => {
    try {
      const response = await window.$comms.request("login", {token})
      if(response.success) {
        usePreferences.setState({
          nickname: response.user.firstName + " " + response.user.lastName
        })
        useAuthentication.setState({
          isAuthenticated: true,
          user: response.user
        })
      }
    } catch(e) {
      console.dir(e)
      useAuthentication.setState({
        isAuthenticated: false,
        user: null
      })
      window.localStorage.setItem("token", "")
    }
  },
  logout: async () => {
    set({
      isAuthenticated: false,
      user: null
    })
    window.localStorage.setItem("token", null)
    try {
      window.$comms.request("logout")
      return true
    } catch (e) {
      console.log("Error while trying to logout!")
      console.dir(e)
    }
  }
}))

window.$comms.addListener("refreshtoken", (token) => {
  window.localStorage.setItem("token", token)
})
window.$comms.addListener("logout", () => {
  useAuthentication.setState({
    isAuthenticated: false,
    user: null
  })
})