import { shallow } from "zustand/shallow"
import usePeers from "stores/peers"
import OwnStream from "./OwnStream"
import { useRef, useState } from "react"
import UserVideo from "./UserVideo"
import Button from "./Button"
import {FullScreenIcon} from "../SVGComponents/FullScreenIcon";
import {ExitFullScreenIcon} from "../SVGComponents/ExitFullScreenIcon";
import isProfane from "badwords"
import { usePreferences } from "stores/preferences"
const PresentationScreen = () => {
  console.log("PresentationScreen")
  // const { selectedPeer } = usePeers(
  //   state=>({selectedPeer: state.selectedPeer}),
  //   shallow
  //   )
  const { selectedPeer } = usePeers()
  const SelectPeer = usePeers(state=>state.selectPeer, shallow)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const {nickname: displayName} = usePreferences()
  const bigScreen = useRef()
  const toggleFullScreen = () => {
    if(isFullscreen) {
      document.exitFullscreen()
    } else {
      bigScreen.current.requestFullscreen()
    }
    setIsFullscreen(!isFullscreen)
  }

  return (
    <div ref={bigScreen} className="SelectedParticipant">
      {
        selectedPeer ? (
          <UserVideo peer={selectedPeer}/>
        ) : (
          <OwnStream/>
        )
      }
      <div className="BigVideoOptions">
        <div style={{
          color: '#fff',
          fontSize: '17px',
          fontFamily: 'Poppins-300',
          display: 'flex',
          alignItems: 'flex-end',

        }} >
          <span
            style={{
              backgroundColor:'rgba(0, 0, 0, 0.6)',
              maxWidth:'150px',
              overflow:'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              padding: '0 15px',
              borderRadius: '14px'}}
            >{ selectedPeer ? 
              isProfane(selectedPeer.nickname) ? "******" : selectedPeer.nickname 
            : 
              isProfane(displayName) ? "******" : displayName
            }
          </span>
        </div>
        <Button style={{padding: '10px 10px', border: '1px solid transparent', borderRadius: '17px'}} icon={isFullscreen ? <ExitFullScreenIcon fill={"#fff"}/>:<FullScreenIcon fill={'#fff'} />} buttonStyle="transparentBlack" onClick={toggleFullScreen}/>
      </div>
    </div>
  )
}
export default PresentationScreen
