import "react-phone-input-2/lib/style.css";
import Input from "components/input/input"
import Authentication from "../authentication"
import Window from "../window"
import { useState } from "react"
import { useRef } from "react"
import Checkbox from "components/checkbox"
import PhoneInput from "react-phone-input-2"
import { useCountryLocation } from "stores/location";
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate()
  const { register, isAuthenticated } = useAuthentication() 
  const { countryCode } = useCountryLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [phone, setPhone] = useState("")
  useEffect(()=>{
    if(isAuthenticated) {
      navigate("/")
    }
  },[])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setFirstName("")
    setLastName("")
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    setTermsAgreed(false)
    setPhone("")
  }
  const attemptRegistration = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const result = await register({
      email: email.toLowerCase(),
      password,
      firstName: firstName.toLowerCase(),
      lastName: lastName.toLowerCase(),
      phoneNumber: Number(phone)
    })
    if(result === true) {
      resetState()
      setSuccess("Registered successfully!")
      setTimeout(()=>{
        navigate(`/verify?email=${email}`)
      }, 2000)
    } else {
      setError(result)
      setIsLoading(false)
      setTimeout(()=>{
        setError("")
        if(result === "Please verify your email.") {
          navigate(`/verify?email=${email}`)
        }
      }, 2000)
    }
  }
  return (
    <Authentication>
      <Window
        title="Register"
        message="Processing your application"
        isLoading={isLoading}
        minHeight="545px"
        error={error}
        success={success}>
        <h1 className="text-center">Register</h1>
        <form onSubmit={attemptRegistration}>
          <Input
            minLength="2"
            name="firstname"
            label="First Name"
            pattern="[A-Za-z]{1,32}"
            required
            capitalize
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)} />
          <Input
            minLength="2"
            name="lastname"
            label="Last Name"
            pattern="[A-Za-z]{1,32}"
            value={lastName}
            required
            capitalize
            onChange={(e) => setLastName(e.target.value)} />
          <PhoneInput
            value={phone}
            country={countryCode.toLowerCase()}
            searchPlaceholder="Phone Number"
            specialLabel="Phone"
            containerClass="customPhoneInputContainer"
            buttonClass="customPhoneInputButton"
            inputProps={{
              required: true,
              minLength: "6",
              maxLength: 30
            }}
            onChange={(p) => setPhone(p)}
            inputClass="customPhoneInput"
            preferredCountries={["ae", "in", "ru", "de"]}
            priority={{ae: 0, in: 1, ru: 2, de: 3}}
            dropdownClass="customPhoneInputDropdown"
          />
          <Input
            name="email"
            label="Email"
            type="email"
            value={email}
            autoComplete="username"
            required
            onChange={(e) => setEmail(e.target.value)} />
          <Input
            name="password"
            label="Password"
            autoComplete="new-password"
            type="password"
            minLength="6"
            maxLength="32"
            value={password}
            validations={[
              {
                message: "Requires at least 6 characters",
                check: (input) => {
                  return input.length >= 6
                }
              },
              {
                message: "Requires at least one uppercase letter (A-Z)",
                check: (input) => {
                  return input.match(/[A-Z]/)
                }
              },
              {
                message: "Requires at least one lowercase letter (a-z)",
                check: (input) => {
                  return input.match(/[a-z]/)
                }
              }
            ]}
            pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,32}$"
            required
            onChange={(e) => setPassword(e.target.value)} />
          <Input
            name="confirmPassword"
            label="Repeat Password"
            autoComplete="new-password"
            type="password"
            title="Passwords must match!"
            pattern={`(?<![\w\d])${password}(?![\w\d])`}
            value={confirmPassword}
            required
            onChange={(e) => setConfirmPassword(e.target.value)} />
          <Checkbox
            value={termsAgreed}
            onClick={() => setTermsAgreed(!termsAgreed)}
            required
            label="I agree to the"
            linkLabel="Booom Disclaimer"
            linkTo="/disclaimer" />
          <input
          className={
            `MainButton ${
              phone.length > 4 &&
              password === confirmPassword &&
              firstName && lastName && email && password && confirmPassword ? 
              "" : "disabled"
            }`} 
            type="submit"
            required />
        </form>
      </Window>
    </Authentication>
  )
}
export default Register