import { create } from 'zustand'
import comms from '../comms'
import { useAuthentication } from './authentication'
import usePeers from './peers'
import { useRooms } from './rooms'
export const useConnection = create((set) => ({
  isConnected: false,
  connect: ()=>{ comms.initialize() }
}))
comms.setSpecial("connect", () => {
  useConnection.setState({isConnected: comms.socket.connected})
  if(comms.socket.connected) {
    const token = window.localStorage.getItem("token")
    if(token) {
      useAuthentication.getState().useToken(token)
    }
  }
})
comms.setSpecial("disconnect", () => {
  useConnection.setState({isConnected: comms.socket.connected})
  if(!comms.socket.connected) {

  }
})

comms.addListener("clients", (e) => {
  const { addPeer } = usePeers.getState()
  for(let i=0; i<e.length; i++) {
    const client = e[i]
    const { increaseClientCounter } = useRooms.getState()
    if(client.socketId !== comms.socket.id) {
      addPeer(comms.socket, client, true)
      increaseClientCounter()
    }
  }
  usePeers.setState({receivedClients: true})
})
comms.addListener("joined", (data) => {
  const { increaseClientCounter } = useRooms.getState()
  increaseClientCounter()
  const { addPeer } = usePeers.getState()
  addPeer(comms.socket, data)
})
comms.addListener("signal", (e)=> {
  const { peers } = usePeers.getState()
  peers[e.client].signal(e.data)
})

comms.addListener("leave", (e) => {
  const { decreaseClientCounter } = useRooms.getState()
  decreaseClientCounter()
})