import {useMediaQuery} from "react-responsive";


export const MediaScreen = (customQuery='100px') => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1200px)'
    })
    const isSmallLaptopScreen = useMediaQuery({
        query: '(max-width: 1200px)'
    })
    const isTablet = useMediaQuery({ query: '(max-width: 900px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 490px)' })
    const isCustomQuery = useMediaQuery({ query: `(max-width: ${customQuery})` })
    return {
        isDesktopOrLaptop: isDesktopOrLaptop,
        isSmallLaptopScreen: isSmallLaptopScreen,
        isTablet: isTablet,
        isMobile: isMobile,
        isCustomQuery: isCustomQuery
    }
}