import "./title-bar.scss"
import Icon from "components/icon/icon"
import Logo from "components/logo"
import UserControl from "./user/user-control"
import Beta from "components/beta/beta"
import LeaveRoom from "./room-controls/leave-room"
import OpenChat from "./room-controls/open-chat"
import { useRooms } from "stores/rooms"
import CopyLink from "./room-controls/copy-link"

const TitleBar = () => {
  const { current: isInRoom } = useRooms()
  return (
    <div className="titleBarContainer container-fluid">
      <div className="row p-2 px-3">
        <div className="col">
          <div className="d-flex align-items-center">
            <div className="betaLogo" style={{minWidth: "100px", marginRight: "15px"}}>
              <Logo/>
              <Beta/>
            </div>
            { isInRoom ? <LeaveRoom/> : null}
            <div style={{width: "4px", height: "1px"}}></div>
            { isInRoom ? <CopyLink/> : null}
          </div>
        </div>
        <div className="col d-flex flex-direction-row justify-content-end align-items-end gap-1">
          { isInRoom ? <OpenChat/> : null}
          <UserControl/>
        </div>
      </div>
    </div>
  )
}
export default TitleBar