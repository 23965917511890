import { useCallback, useEffect } from "react"
import useDevices from "stores/devices";
import { useState } from "react";
const OwnStream = ({ stream = null}) => {
  if (!stream) {
    stream = useDevices().viewStream
  }
  console.log('OwnStream called ', stream)
  const [cameraVideo, setCameraVideo] = useState(null)
  const [localTrack, setLocalTrack] = useState(stream.getVideoTracks()[0] ?? null)
  const ownCameraRef = useCallback(video => {
    setCameraVideo(video)
  }, []);
  useEffect(()=>{
    if (cameraVideo && stream) {
      cameraVideo.addEventListener("canplay", ()=>{
        // console.log(`Video with id: OwnStream should play.`)
        cameraVideo.play()
      })
      if (stream.getVideoTracks().length) {
        setLocalTrack(stream.getVideoTracks()[0])
      }
      cameraVideo.srcObject = stream
    }

  }, [cameraVideo])
  if (cameraVideo && stream) {
    const videoTracks = stream.getVideoTracks()
    if (videoTracks.length && localTrack?.id !== videoTracks[0].id) {
      setLocalTrack(videoTracks[0])
      cameraVideo.srcObject = stream
    }
  }
  const hasVideo = () => {
    return stream && stream.active && stream.getVideoTracks().length > 0
  }

  return (
    stream.active ? (
      <>
        <video
          style={ownCameraRef && hasVideo() ? {} : {display: "none"}}
          ref={ownCameraRef}
          id={"ownStream"}
          key={"ownStream"}
          muted={true}
          playsInline
        />
        {
          !hasVideo() ? (
            <div className="user">
              <img src="/images/notLoggedIn.svg" width={128}/>
            </div>
          ) : null
        }
      </>
      ):(
        <div className="user">
          <img src="/images/notLoggedIn.svg" width={128}/>
        </div>
      )
  ) 
}
export default OwnStream
