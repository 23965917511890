import "./beta.scss"
import * as React from "react"
const Beta = (props) => {
  return (
    <div className="beta">
      <p>Beta</p>
    </div>
  )
}
export default Beta
