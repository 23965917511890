import "react-phone-input-2/lib/style.css";
import Input from "components/input/input"
import Authentication from "../authentication"
import Window from "../window"
import { useState } from "react"
import Checkbox from "components/checkbox"
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Recover = () => {
  const navigate = useNavigate()
  const { recover, isAuthenticated } = useAuthentication()
  const [searchParams] = useSearchParams()
  const emailParam = searchParams.get("email")
  const codeParam = searchParams.get("code")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  useEffect(() => {
    if (isAuthenticated || !codeParam || !emailParam) {
      navigate("/")
    }
  }, [])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setPassword("")
    setConfirmPassword("")
    setTermsAgreed(false)
  }
  const attemptPasswordRecovery = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const result = await recover({
      email: emailParam.toLowerCase(),
      code: Number(codeParam),
      password
    })
    if (result === true) {
      resetState()
      setSuccess("Password Successfully Changed!")
      setTimeout(()=>{
        navigate("/login")
      }, 2000)
    } else if (result === "Invalid Code") {
      setError(result)
      setIsLoading(false)
      setTimeout(() => {
        setError("")
      }, 2000)
    } else {
      setError("Something went wrong!")
      setIsLoading(false)
      setTimeout(() => {
        setError("")
      }, 2000)
    }
  }
  return (
    <Authentication>
      <Window
        title="Reset Password"
        message="Resetting password"
        isLoading={isLoading}
        minHeight="235px"
        error={error}
        success={success}>
        <h1 className="text-center">Reset Password</h1>
        <form onSubmit={attemptPasswordRecovery}>
        <Input
            name="email"
            label="Email"
            type="email"
            defaultValue={emailParam}
            autoComplete="username"
            required
            style={{
              opacity: 0,
              height: 0,
            }}
            readOnly />
        <Input
            name="password"
            label="Password"
            autoComplete="new-password"
            type="password"
            minLength="6"
            maxLength="32"
            value={password}
            validations={[
              {
                message: "Requires at least 6 characters",
                check: (input) => {
                  return input.length >= 6
                }
              },
              {
                message: "Requires at least one uppercase letter (A-Z)",
                check: (input) => {
                  return input.match(/[A-Z]/)
                }
              },
              {
                message: "Requires at least one lowercase letter (a-z)",
                check: (input) => {
                  return input.match(/[a-z]/)
                }
              }
            ]}
            pattern="^(?=.*[a-z])(?=.*[A-Z]).{6,32}$"
            required
            onChange={(e) => setPassword(e.target.value)} />
          <Input
            name="confirmPassword"
            label="Repeat Password"
            autoComplete="new-password"
            type="password"
            title="Passwords must match!"
            pattern={`(?<![\w\d])${password}(?![\w\d])`}
            value={confirmPassword}
            required
            onChange={(e) => setConfirmPassword(e.target.value)} />
          <Checkbox
            value={termsAgreed}
            onClick={() => setTermsAgreed(!termsAgreed)}
            required
            label="I agree to the"
            linkLabel="Booom Disclaimer"
            linkTo="/disclaimer" />
          <input
            className={
              `MainButton ${
                password && confirmPassword && password.length > 3 && password === confirmPassword ?
                "" : "disabled"
              }`}
            type="submit"
            required />
        </form>
      </Window>
    </Authentication>
  )
}
export default Recover