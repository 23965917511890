import { useCallback, useEffect, useState } from "react"
import useDevices from "stores/devices";

const UserVideo = ({peer}) => {
  console.log("UserVideo ")
  console.log(peer)
  const stream = peer.currentStream
  const [userVideo, setUserVideo] = useState(null)
  const [streamIsActive, setStreamIsActive] = useState(stream && stream.active)
  const { isDeafened } = useDevices()
  // const { audioSinkId: audioOutput } = useDevices()
  const userVideoRef = useCallback(video => {
    setUserVideo(video)
  }, []);

  useEffect(()=>{
    try{
      if (userVideo && stream) {
        userVideo.addEventListener("canplay", ()=>{
            // if(audioOutput) {
            //   userVideo.setSinkId(audioOutput)
            // }
            userVideo.play()
        })
        userVideo.addEventListener('ended', () => {
          console.log('UserVideo: Video ended')
        })
        userVideo.addEventListener('error', (e) => {
          console.log('UserVideo: Video error', e)
        })
        userVideo.addEventListener('stalled', () => {
          console.log('UserVideo: Video stalled')
        })

        userVideo.srcObject = stream
      }
    } catch (e) {
      console.log("userVideo err:", e)
    }
    if(userVideo) {
      // Check for stream changing periodically
      // const interval = setInterval(()=>{
        if(userVideo.srcObject && userVideo.srcObject.active !== peer.currentStream.active){
          console.log(`UserVideo interval updated stream to ${peer.currentStream.id}`)
          userVideo.srcObject = peer.currentStream
          //todo: update here after implementing volume in devices
          userVideo.volume = isDeafened ? 0 : 1
        }
      // },500)
      // return ()=>{
      //   clearInterval(interval)
      // }
    }
  }, [userVideo])

  if(userVideo) {

    if (peer.shouldUpdate || userVideo.srcObject?.id !== stream?.id) {
      console.log(`UserVideo updated stream to ${peer.currentStream.id}`)
      userVideo.srcObject = stream
      stream.addEventListener('addtrack', (trackEvent) => {
        userVideo.srcObject = stream
      })
      peer.shouldUpdate = false
    }
    if(userVideo.srcObject && streamIsActive !== userVideo.srcObject.active) {
      setStreamIsActive(userVideo.srcObject.active)
    }
  }
  const activeStream = () => {
    if(stream && stream.getTracks().length > 0) {
      return true
    }
    return false
  }
  const containsVideo = () => {
    return activeStream() && stream.getVideoTracks().length > 0
  }

  return activeStream() ? (
  <>
    <video
        ref={userVideoRef}
        id={peer._id}
        key={peer._id}
        style={!containsVideo() ? {display: 'none'} : {}}
        playsInline
    />
    {
    !containsVideo() ? (
      <div className="user">
        <img src="/images/notLoggedIn.svg" width={128}/>
      </div>
    ) : null
    }
  </>

  ) : (
    <div className="user">
      <img src="/images/notLoggedIn.svg" width={128}/>
    </div>
  )
}

export default UserVideo
