import {useState} from "react"
import Button from "./Button"
import {ShareScreen} from "../SVGComponents/shareScreen";
import {MuteIcon} from "../SVGComponents/muteIcon";
import {DeafenIcon} from "../SVGComponents/deafenIcon";
import {SettingsIcon} from "../SVGComponents/SettingsIcon";
import DeviceSelectors from "./DeviceManager";
import {MediaScreen} from "./MediaScreen";
import useDevices from "stores/devices";
const BigButtonsContainer = ({meetingSettingsContent,style}) => {
  const { startScreenSharing, stopScreenSharing, isMuted, isDeafened, setIsDeafened, setIsMuted } = useDevices()
  const { isMobile } = MediaScreen()

  const [ sharing, setSharing ] = useState(false)
  const [ muted, setMuted ] = useState(isMuted)
  const [ deafened, setDeafened ] = useState(isDeafened)

  // Mute
  const toggleMute = async () => {
    setIsMuted(!isMuted)
    setMuted(!isMuted)
  }
  // Deafen
  const toggleDeafen = async () => {
    setIsDeafened(!isDeafened)
    setDeafened(!isDeafened)
  }

  const shareScreenToggle = async () => {
    if(sharing === false) {
      setSharing(await startScreenSharing())
    } else {
      await stopScreenSharing()
      setSharing(false)
    }
  }
  return (
    <div className="BigButtonsContainer" style={style}>
      <Button
          textSize={"17px"}
          style={{
              height: '40px'
          }}
          text={sharing ? "Stop Sharing" : "Share Screen"}
          icon={<ShareScreen fill={'#fff'}/>}
          buttonStyle="shareScreen"
          onClick={shareScreenToggle}
      />
      <Button
          text={muted ? "Unmute" : "Mute"}
          textSize={"17px"}
          style={{marginLeft: 0, height: '40px'}}
          icon={<MuteIcon fill={'#000'}/>}
          buttonStyle="control"
          onClick={toggleMute}
      />
      <Button
        text={deafened ? "Enable All Sounds" : "Disable All Sounds"}
        icon={<DeafenIcon fill={'#000'}/>}
        textSize={"17px"}
        style={{marginLeft: 0, height: '40px'}}
        buttonStyle="control"
        onClick={toggleDeafen}
      />
        <Button
        icon={<SettingsIcon fill={'#fff'}/>}
        textSize={"17px"}
        text={isMobile && 'Settings'}
        style={{
            marginLeft: 0,
            width: !isMobile && '150px',
            height: '40px',
            border: '1px solid transparent'
        }}
        buttonStyle="shareScreen"
        className='settings'
        onClick={()=>{
            meetingSettingsContent(
                <>
                    <span style={{fontSize: '17px', fontFamily: 'Poppins-400'}}>Meetings Settings</span>
                    <DeviceSelectors/>
                </>
            )
        }}
      />
    </div>
  )
}

export default BigButtonsContainer
