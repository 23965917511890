import { useRef } from "react"
import { ChatIcon } from "../SVGComponents/chatIcon"
import { useEffect } from "react"
import Button from "../secure/Button"
import { useState } from "react"
import Message from "./Message"
import BadWords from "badwords"
import { useChatroom } from "stores/chatroom"
import { usePreferences } from "stores/preferences"
const Chat = ({messages, sendMessage}) => {
  const { displayChatroom } = useChatroom()
  const scrollableContainerRef = useRef(null);
  const [newMessage, setNewMessage] = useState(``)
  const { nickname: displayName } = usePreferences()
  const messageList = []
  if(messages) {
    messages.forEach((message, index) => {
      console.log(message)
      console.log(message.time)
      messageList.push(<Message key={`${index}`} sender={message.sender.userData ? message.sender.userData.nickname : message.sender} message={message.content} date={`${message.time.getHours()}:${message.time.getMinutes()+3}`}/>)
    })
  }
  const sendMsg = () => {
      if (newMessage!=='') {
        // addMessage(displayName, BadWords(newMessage) ? "****" : newMessage)
        // messagePeers(BadWords(newMessage) ? "****" : newMessage)
        sendMessage(BadWords(newMessage) ? "****" : newMessage, displayName)
        setNewMessage("")
      }
  }
  useEffect(() => {
    if(displayChatroom){
      const container = scrollableContainerRef.current;
      container.scrollIntoView({behavior: 'smooth'})
      container.scrollTo(0,99999999)
    }

  }, [displayChatroom, messages]);
  return displayChatroom ? (
    <div className={`MeetingChatContainer ${displayChatroom ? 'chatActive': ''}`}>
      <div className='MeetingChatTabs'>
        <button className='tab active'><ChatIcon fill={'#fff'}/>Chat</button>
        {/* <button className='tab'><NotesIcon fill={'#4423b6'}/>Notes</button> */}
      </div>
      <div className='ChatMessagesContainer' style={{ overflow: 'auto' }}>
        <div className='messages' >
          {/*  Start of the map messages*/}
          {messageList}
          {/*  End of the map messages*/}

          <span ref={scrollableContainerRef}/>
        </div>
      </div>
      <div className='writeMessageTextArea'>
        <textarea  placeholder={'Type your message here...' } value={newMessage} onChange={(e=>{
          if(e.target.value === "\n") {
            e.preventDefault()
          } else {
            setNewMessage(e.target.value)
          }
        })} onKeyDown={e=>{
          if (e.key === 'Enter' && e.shiftKey === false && e.target.value !== '') {
            e.preventDefault()
            if(e.target.value.length > 0) {
              sendMsg()
            } else {
              setNewMessage("")
            }
          }
        }}></textarea>
      </div>
        <div style={{padding:'0 10px 9px'}}>
      <Button
          style={{
            height: '48px'
          }}
          buttonType={'submit'}
          buttonStyle="widePurple"
          text="Send Message"
          onClick={sendMsg}
          disabled={false}/>
            </div>
    </div>
  ) : (
    <></>
  )

}
export default Chat