import Icon from "components/icon/icon"
import Loader from "./loader"

const Window = ({
  title="Authenticating",
  children,
  isLoading,
  message,
  error,
  success,
  minHeight="none"}) => {
  const loadingScreen = (
    <div className="loaderScreen">
      <h1 className="text-center">{title}</h1>
      <div className="spacer">
        <Loader/>
        <br />
        <p className="text-center">{message}</p>
      </div>
    </div>
  )
  const errorScreen = (
    <div className="errorScreen">
      <h1 className="text-center">{title}</h1>
      <div className="spacer">
        <Icon name="error"/>
        <br />
        <p className="text-center">{error}</p>
      </div>
    </div>
  )

  const successScreen = (
    <div className="successScreen">
      <h1 className="text-center">{title}</h1>
      <div className="spacer">
        <Icon name="success"/>
        <br />
        <p className="text-center">{success}</p>
      </div>
    </div>
  )
  return (
    <div className="authenticationWindow" style={{
      minHeight
    }}>
      {
        isLoading ? loadingScreen : error ? errorScreen : success ? successScreen : children 
      }
    </div>
  )
}
export default Window