import Logo from "components/logo"
import "./authentication.scss"
const Authentication = ({children}) => {
  return (
    <div className="authenticationContainer">
      <div className="authenticationHeader">
        <Logo/>
        <p style={{userSelect: "none"}}>Next-gen Communication Services</p>
      </div>
      {children}
    </div>
  )
}
export default Authentication