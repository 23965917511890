import Input from "components/input/input"
import JoinRoom from "./join"
import CreateRoom from "./create"

const { default: WindowLayout } = require("components/windowLayout/window-layout")

const Rooms = () => {
  return (
    <WindowLayout>
      <div className="centerRooms">
        <div className="container-fluid w-100">
          <div className="row">
            <CreateRoom className="createRoom col-sm-6 col-lg-6"/>
            <JoinRoom className="joinRoom col-sm-6 col-lg-6"/>
          </div>
        </div>
      </div>
    </WindowLayout>
  )
}

export default Rooms