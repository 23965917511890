import Countdown from "components/countdown"
import Logo from "components/logo"
import { useNavigate } from "react-router-dom"

const MaxTime = () => {
  const navigate = useNavigate()
  return (
    <div className="d-flex h-100 w-100 justify-content-center align-items-center">
      <div className="d-flex-column justify-content-center align-items-center">
        <div className="mx-auto">
          <div className="mx-auto m-3" style={{width: "300px"}}>
            <Logo/>
          </div>
        </div>
        <h1 className="text-center p-2">Session Ended</h1>
        <p className="w-55 p-1">Maximum room time of 1 hour has been exceeded, create or join a new room to continue your conversation.</p>
        <p className="w-55 p-1">You will be returned home in <Countdown time={15} onEnded={()=>{navigate("/")}}/>s...</p>
      </div>
    </div>
  )
}

export default MaxTime
{/* <p className="w-55 p-1">You will be returned home in <Countdown onEnded={()=>{navigate("/")}}/>s...</p> */}