import { useState } from "react"
import "./input.scss"
import { useRef } from "react"
import { useEffect } from "react"
import Icon from "components/icon/icon"
const Input = ({
  name,
  value,
  label="label",
  onChange,
  type="text",
  required,
  pattern,
  minLength="3",
  maxLength="30",
  size="50",
  style={},
  autoComplete,
  title,
  capitalize = false,
  validations=[],
  readOnly,
  defaultValue
  // Example:
  // validations = [
  //   {
  //     message: "Requires at least 6 characters",
  //     check: (input)=>{
  //       return input.length >= 6
  //     }
  //   },
  //   {
  //     message: "Requires at least one uppercase letter (A-Z)",
  //     check: (input)=>{
  //       return input.match(/[A-Z]/)
  //     }
  //   },
  //   {
  //     message: "Requires at least one lowercase letter (a-z)",
  //     check: (input)=>{
  //       return input.match(/[a-z]/)
  //     }
  //   }
  // ]
}) => {
  const [isFocused, setIsFocused] = useState(value && value.length !== 0 || defaultValue && defaultValue.length !== 0)
  const [errors, setErrors] = useState([])
  const inputRef = useRef()
  const checkValidations = () => {
    const result = []
    validations.forEach((validation, idx) => {
      if(!validation.check(value)) {
        result.push(
          <div className="inputError" key={`${name}${type}${idx}`}>
            <Icon name="warning"/>
            <p>
              {validation.message}
            </p>
          </div>
        )
      }
    })
    setErrors(result)
  }
  useEffect(()=>{
    if(value && value.length > 0) {
      checkValidations()
    } else {
      if(errors.length > 0) {
        setErrors([])
      }
    }
  },[value])
  return (
    <div className={`inputWrapper ${errors.length > 0 ? "errorsPresent" : ""}`} style={style}>
      <div className="inputContainer">
        <label
          onClick={()=>{
              if(inputRef && inputRef.current) {
                inputRef.current.focus()
              }
            }
          }
          htmlFor={name}
          className={isFocused ? "selected" : ""}>
          {label}
        </label>
        <input
          className={`${capitalize ? "capitalize" : ""}`}
          minLength={minLength}
          maxLength={maxLength}
          ref={inputRef}
          value={value}
          id={name}
          title={title}
          onFocus={()=>setIsFocused(true)}
          type={type}
          size={size}
          pattern={pattern}
          required={required}
          autoComplete={autoComplete}
          onBlur={()=>{
            if(!value || value && value.length === 0) {
              setIsFocused(false)
            }
          }}
          readOnly={readOnly}
          defaultValue={defaultValue}
          onChange={onChange}/>
      </div>
      {errors}
    </div>
  )
}
export default Input