import "./Chatroom.scss"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { shallow } from "zustand/shallow"
import PresentationScreen from "./PresentationScreen"
import Participants from "./Participants"
import BigButtonsContainer from "./BigButtonsContainer"
import Chat from "./Chat"
import useMessages from "stores/messages"
import './ChatRoomGrid.scss'
import {CollapseIcon} from "../SVGComponents/CollapseIcon";
import {MediaScreen} from "./MediaScreen";
import InitialDeviceSelection from "./InitialSettingsSelection";
import { useAuthentication } from "stores/authentication"
import useDevices from "stores/devices"
import { usePreferences } from "stores/preferences"
import { useRooms } from "stores/rooms"
import { useChatroom } from "stores/chatroom"
import usePeers from "stores/peers"


const ChatRoom = ({modalContent}) => {
  const { isMobile } = MediaScreen()
  const {displayChatroom, setDisplayChatroom} = useChatroom()
  const { setNickname: setDisplayName } = usePreferences()
  const { isAuthenticated, user } = useAuthentication()
  const { clearPeers } = usePeers(state=>({clearPeers: state.clearPeers, increaseTest: state.increaseTest}), shallow)
  const clearMessages = useMessages(state=>state.clearMessages, shallow)
  const [collapseButtons, setCollapseButtons] = useState(true)
  const { devicesAreReady, resetDevices } = useDevices()
  const { clearRoom } = useRooms()

  useEffect(()=>{
    if(isAuthenticated) {
      setDisplayName(`${user.firstName} ${user.lastName}`)
    }
    return ()=>{
      console.log("Closing Connections...")
      clearPeers()
      clearRoom()
      clearMessages()
      resetDevices()
      if(!isAuthenticated) {
        setDisplayName("")
      }
    }
  }, [])


  return <div className="Chatroom">
    {
      !devicesAreReady ? (
        <div style={{width: '100%'}}>
          <InitialDeviceSelection/>
        </div>
      ) : (
        <div className={`containerChatRoom ${!displayChatroom ? "chatActive":""}`}>
          <div className="Video">
            <PresentationScreen />
          </div>
          <div className="Chat" style={!displayChatroom ? {}:{display:'none'}}>
            <Chat />
          </div>
          <div className="Guests">
            <Participants chatIsActive={!displayChatroom}/>
          </div>
          <div className="Options">
            <div className="meetingSettings">
              {isMobile &&
                <div
                  className={`container-icon-collapse`}
                  style={{
                    padding:collapseButtons ? '0' :'10px 0 0 0',
                    transform:collapseButtons ? 'rotate(0deg)' :'rotate(180deg)'
                  }}
                  onClick={()=> {
                    setCollapseButtons(!collapseButtons)
                    setDisplayChatroom(false)
                  }}>
                  <CollapseIcon fill={'#000'} />
                </div>
              }

              <BigButtonsContainer
                meetingSettingsContent={(e)=>{
                  modalContent(e)
                }}
                style={{
                  transition: 'height ease .2s',
                  overflow:'hidden',
                  height: isMobile ? collapseButtons ? '0svh' : '24svh' : ''
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  </div>
}
export default ChatRoom
