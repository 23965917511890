import useDevices from "stores/devices"
import { useEffect, useState } from "react";
import Button from "./Button";
import {MediaDeviceType} from "../../../../hardcoded/streamHelper";
import CheckBoxComponent from "./CheckBoxComponent/CheckBoxComponentOld";

const InitialDeviceSelection = () => {
  const { getDefaultDeviceList, requestDevice, setDevices } = useDevices()

  const [defaultDevices, setDefaultDevices] = useState(null)

  const [useAudio, setUseAudio] = useState(true)
  const [useVideo, setUseVideo] = useState(true)

  useEffect(() => {
    if (!defaultDevices) {
      getDefaultDeviceList().then(async devices => {
        if (devices.video === null && devices.input === null && devices.output === null) {
          const videoTrack = await requestDevice(MediaDeviceType.VideoInput, null)
          const audioTrack = await requestDevice(MediaDeviceType.AudioInput, null)

          videoTrack?.stop()
          audioTrack?.stop()

          const deviceList = await getDefaultDeviceList()
          setDefaultDevices(deviceList)
        } else {
          setDefaultDevices(devices)
        }
      })
    }
  }, [defaultDevices])

  const onEnterRoom = async clickEvent => {
    if (!defaultDevices) {
      alert('Cannot enter room before device initialization.')
      return
    }
    const devices = {output: defaultDevices.output}
    if (useAudio) {
      devices.input = defaultDevices.input
    }
    if (useVideo) {
      devices.video = defaultDevices.video
    }

    await setDevices(devices)
  }

  return (
    setDefaultDevices ? (
      <div style={{
        width: 256,
        position: 'absolute', left: '50%', top: '40vh',
        transform: 'translate(-50%)',
      }}>
        {
          defaultDevices?.input ? (
            <CheckBoxComponent
              isChecked={useAudio}
              label={'Use Microphone?'}
              changeAction={()=>{setUseAudio(!useAudio)}}/>
          ) : (
            <p>No microphone detected.</p>
          )
        }
        {
          defaultDevices?.video ? (
            <CheckBoxComponent
            isChecked={useVideo}
            label={'Use Camera?'}
            changeAction={()=>{setUseVideo(!useVideo)}}/>
          ) : (
            <p>No camera detected.</p>
          )
        }
        <Button onClick={onEnterRoom} buttonStyle="wideCyan" style={{marginLeft: "5px", height:'48px'}} text={'Enter room'}/>
      </div>
    ) : (
      <span>Loading...</span>
    )
  )
}
export default InitialDeviceSelection
