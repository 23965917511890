import { ReactSVG } from "react-svg"
import svgs from "./icons"
const Icon = ({name, color, onClick, size, className}) => {
  return (
    <ReactSVG
      onClick={onClick}
      beforeInjection={(svg) => {
        if(size) {
          svg.setAttribute('style', `width: ${size}px; height: ${size}px`)
        }
        if(className) {
          svg.classList.add(className)
        }
      }}
      src={svgs[name]}/>
  )
}
export default Icon