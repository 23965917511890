import Icon from "components/icon/icon"
import { MediaScreen } from "components/pages/Rooms/chatroom/encrypted/MediaScreen"
import { useEffect } from "react"
import { useState } from "react"

const CopyLink = () => {
  const [ displayCopied, setDisplayCopied ] = useState(false)
  const { isMobile, isTablet } = MediaScreen()
  useEffect(()=>{
    let tout = null
    if(displayCopied) {
      tout = setTimeout(()=>{
        setDisplayCopied(false)
      }, 500)
    }
    return () => {
      clearTimeout(tout)
    }
  }, [displayCopied, setDisplayCopied])
  return (
    <div
      className="controlButton d-flex justify-content-center align-items-center"
      onClick={() => {
        navigator.clipboard.writeText(window.location.href)
        if(isMobile || isTablet) {
          window.$addToast(null, "Link Copied!")
        }
        setDisplayCopied(true)
      }}>
      <Icon className="meeting" name="copy" size={20}/>
      <p className="onlyDesktop">{!displayCopied ? "Share Meeting Link" : "Link Copied!"}</p>
    </div>
  )
  }

export default CopyLink