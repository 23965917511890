import "./rooms.scss"
import Checkbox from "components/checkbox"
import Icon from "components/icon/icon"
import Input from "components/input/input"
import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const JoinRoom = (props) => {
  const navigate = useNavigate()
  const [room, setRoom] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  const proceed = (e) => {
    console.log(room)
    e.preventDefault()
    if(!room.includes("?")) {
      navigate(`/chatroom?room=${room}`)
    } else {
      const query = new URLSearchParams(room.split("?")[1])
      const roomCode = query.get("room")
      navigate(`/chatroom?room=${roomCode}`)
    }
  }
  return (
    <div className={`${props.className} d-flex h-100 flex-column justify-content-center align-items-center`}>
      <Icon name="meeting" size="100"/>
      <h1 className="roomsTitle">Join a Room</h1>
      <br></br>
      <br></br>
      <form onSubmit={proceed} className="w-100">
        <Input
          name="join-room"
          label="Meeting Url"
          type="text"
          value={room}
          autoComplete="none"
          minLength="30"
          maxLength="200"
          required
          onChange={(e) => setRoom(e.target.value)} />
        <Checkbox
          value={termsAgreed}
          onClick={() => setTermsAgreed(!termsAgreed)}
          required
          label="I agree to the"
          linkLabel="Booom Disclaimer"
          linkTo="/disclaimer" />
          <br />
        <input
          className={
            `MainButton ${termsAgreed && room && room.length >= 3 ?
              "" : "disabled"
            }`}
          type="submit"
          value="Join Meeting"
          required />
      </form>
    </div>
  )
}
export default JoinRoom