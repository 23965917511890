import Authentication from "components/pages/Authentication/authentication"
import Window from "../window"
import { useNavigate, useSearchParams } from "react-router-dom"
import ReactCodeInput from "react-verification-code-input"
import { useState } from "react"
import { useEffect } from "react"
import { useAuthentication } from "stores/authentication"

const Verify = () => {
  const navigate = useNavigate()
  const {resend, verify, isAuthenticated} = useAuthentication()
  const [searchParams] = useSearchParams()
  const emailParam = searchParams.get("email")
  const [timeLeft, setTimeLeft] = useState(null)
  const [verificationCode, setVerificationCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    if (!emailParam || isAuthenticated) {
      navigate("/register")
    }
  }, [])
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft])
  const initiateResend = () => {
    if(timeLeft === null) {
      resend({email: emailParam})
      setTimeLeft(30)
    }
  }
  const attemptVerification = async () => {
    if(verificationCode && verificationCode.length === 4) {
      setIsLoading(true)
      try {
        const result = await verify({email: emailParam, verificationCode: Number(verificationCode)})
        if(result === true) {
          setSuccess("Verification successful!")
          setIsLoading(false)
          setTimeout(()=>{
            navigate("/")
          },2000)
        } else {
          setError(result)
          setIsLoading(false)
          setTimeout(()=>{
            setSuccess("")
            setError("")
          },2000)
        }
      } catch (e) {
        setError("Something went wrong!")
        setIsLoading(false)
        setTimeout(()=>{
          setError("")
        },2000)
      }
    }
  }
  return (
    <Authentication>
      <Window
        title="Verify Email"
        message="Verifying code validity"
        isLoading={isLoading}
        minHeight="280px"
        error={error}
        success={success}>
        <h1 className="text-center">Verify Email</h1>
        {emailParam ? (
          <>
            <p className="text-center">Enter verification code received at: {emailParam}</p>
            <div className="d-flex justify-content-center w-100">
              <ReactCodeInput
                placeholder={["1", "2", "3", "4"]}
                fields={4}
                onChange={(e) => setVerificationCode(e)}
              />
            </div>
            <br />
            <div className="d-flex gap-2 mb-3">
              <button onClick={initiateResend} className={`secondaryButton ${timeLeft !== null ? "disabled" : ""}`}>
                {
                  timeLeft !== null ? (
                    timeLeft > 28 ? "Sent!" : `Resend (${timeLeft}s)` 
                  ) : (
                    "Resend"
                  )
                }
              </button>
              <button
                className={`mainButton ${verificationCode && verificationCode.length === 4 ? "" : "disabled"}`}
                onClick={attemptVerification}
                disabled={verificationCode === undefined || verificationCode.length !== 4}>Verify</button>
            </div>
          </>
        ) : null}

      </Window>
    </Authentication>
  )
}

export default Verify