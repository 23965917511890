import WindowLayout from "components/windowLayout/window-layout"
import "./rooms.scss"
import Icon from "components/icon/icon"
import Loader from "components/loader"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import Countdown from "components/countdown"
import Checkbox from "components/checkbox"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRooms } from "stores/rooms"
const CreateRoomOptions = () => {
  const navigate = useNavigate()
  const [currentState, setCurrentState] = useState("setup")
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoAccept, setAutoAccept] = useState(true)
  const [autoUpgrade, setAutoUpgrade] = useState(true)
  const { createRoom } = useRooms()
  const roomName = searchParams.get('roomName');
  const attemptRoomCreation = async () => {
    setCurrentState("creating")
    const success = await createRoom({
      name: roomName,
      autoAccept,
      autoSwitch: autoUpgrade
    })
    if (success) {
      navigate(`/`)
      navigate(`/chatroom?room=${success}`)
    } else {
      setCurrentState("failed")
    }
  }
  useEffect(()=>{attemptRoomCreation()},[])
  const displayOptions = {
    creating: (
      <>
        <br />
        <Loader/>
        <br />
        <h2>Creating Room</h2>
      </>
    ),
    setup: (
      <>
          <Icon name="meeting-room" size={100}/>
          {/* <br/>
          <h3>Room Options</h3>
          <br />
          <div style={{
            maxWidth: "280px"
          }}>
            <Checkbox value={autoAccept} onClick={()=>setAutoAccept(!autoAccept)} label={
              (<span>Auto accept participants (<b style={{color: "#0d6efd", cursor: "help"}} data-tooltip-id="createRoomOptionsToolTip-autoAccept">?</b>)</span>)
            }/>
            <Checkbox value={autoUpgrade} onClick={()=>setAutoUpgrade(!autoUpgrade)} label={
              (<span>Automatically Encrypt Data (<b style={{color: "#0d6efd", cursor: "help"}} data-tooltip-id="createRoomOptionsToolTip-upgrade">?</b>)</span>)
            }/>
          <input
            className="SecondaryButton"
            type="submit"
            onClick={attemptRoomCreation}
            value="Create Meeting"/>
          <ReactTooltip
            id="createRoomOptionsToolTip-autoAccept"
            place="bottom"
            content={
              (<>
                <div style={{maxWidth: "300px"}}>
                  <p style={{margin: "0"}}>Automatically Accept new participants</p>
                  <p style={{margin: "0 0 0 10px"}}>-You will be asked if you want to change the room type to Encrypted.</p>
                </div>

              </>)
            }
          />
          <ReactTooltip
            id="createRoomOptionsToolTip-upgrade"
            place="bottom"
            content={
              (<>
                <div style={{maxWidth: "300px"}}>
                  <p>Automatically change room type from Secure to Encrypted</p>
                  <p style={{margin: "0"}}>Secure:</p>
                  <p style={{margin: "0 0 10px 0"}}>Connect directly to your participants without any intermediaries.</p>
                  <p style={{margin: "0"}}>Encrypted:</p>
                  <p style={{margin: "0 0 0 5px"}}>-You will connect to your participants using an intermediary.</p>
                  <p style={{margin: "0 0 0 5px"}}>-All data traffic is encrypted</p>
                  <p style={{margin: "0 0 0 5px"}}>-This is useful in case you or a participant is blocked by their Internet Service Provider from establishing peer to peer connections.</p>
                  <p style={{margin: "0 0 0 5px"}}>-It is also useful if you want to have more than 6 participants. Otherwise the sheer amount of data between you and your participants would overwhelm your devices.</p>
                </div>
              </>)
            }
          />
          </div>
         */}
      </>
    ),
    failed: (
      <>
        <div style={{color:"red"}}>
          <Icon name="error" size={100}/>
        </div>
        <br />
        <h2>Couldn't create room!</h2>
        <p>Please try again later.</p>
        <p>
          You will be redirected home in: <b>
          <Countdown time={6} onEnded={()=>navigate("/")}/>
          s
          </b>
        </p>
      </>
    ),
  }
  useEffect(()=>{
    if(!roomName || (roomName && roomName.length < 3)) {
      setCurrentState("failed")
    }
  },[])
  return (
    <WindowLayout>
      <div className="createRoomOptions centerRooms d-flex flex-column justify-content-center align-items-center">
        { displayOptions[currentState] }
      </div>
    </WindowLayout>
  )
}

export default CreateRoomOptions