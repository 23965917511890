import { useEffect } from "react"
import "./connecting.scss"
import Logo from "components/logo"
import { useConnection } from "stores/connection"
import { useCountryLocation } from "stores/location"

const Connecting = () => {
  const {connect} = useConnection()
  const {getLocationInformation} = useCountryLocation()
  useEffect(()=>{
    connect()
    getLocationInformation()
  }, [])
  return (
    <div className="connectingScreenContainer">
      <div className="connectingScreen">
        <div className="merge">
          <Logo />
          <span className="loader"></span>
        </div>
        <h1>Connecting to Booom Servers</h1>
        <p>Just a moment while we link you to the network.</p>
      </div>
    </div>
  )
}
export default Connecting