import WindowLayout from "components/windowLayout/window-layout"
import ChatRoom from "./Chatroom"

const Secure = () => {
  return (
    <WindowLayout>
      <ChatRoom/>
    </WindowLayout>
  )
}
export default Secure