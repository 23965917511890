import {MediaScreen} from "./MediaScreen";

const Button = ({buttonStyle,iconWidth,onHover,textSize='20px',buttonType='button',iconHeight, icon, text, style, className, disabled, onClick}) => {
  const {isMobile} = MediaScreen()

  const defaultStyle = {
    color: "white",
    backgroundColor: "#42d3d8",
    borderRadius: "5px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  }
  const styles = {
    default: {
      ...defaultStyle
    },
    wideCyan: {
      ...defaultStyle,
      width: "100%",
      backgroundColor: "#42d3d8",
    },
    widePurple: {
      ...defaultStyle,
      width: "100%",
      backgroundColor: "#4423b6",
      border: "none"
    },
    transparentBlack: {
      backgroundColor: "rgba(0,0,0,0.5)",
      color: "white",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    titleRound: {
      borderRadius: "14px",
      border: "solid 1px #4423b6",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#4423b6",
      cursor: "pointer"
    },
    titleRoundRed: {
      borderRadius: "14px",
      border: "solid 1px #df2020",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#df2020",
      cursor: "pointer",
    },
    Notes: {
      ...defaultStyle,
      backgroundColor: '#ed8d6b',
      color: '#fff'
    },
    shareScreen: {
      ...defaultStyle,
      backgroundColor: "#4423b6",
      width: "100%",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    control: {
      ...defaultStyle,
      backgroundColor: "#f1f2f7",
      color: "black",
      fontFamily: "Poppins-600",
      fontWeight: "600",
      width: "100%",
      marginLeft: "10px",
      border: "none"
    }
  }

  let selectedStyle = buttonStyle ? styles[buttonStyle] : styles["default"]
  selectedStyle = {...selectedStyle , gap: '15px' , ...style}
  if(disabled) {
    selectedStyle = {...selectedStyle, opacity: 0.5, cursor: "not-allowed"}
  }
  return <button type={buttonType}
                 className={`${className} ${buttonStyle}`}
                 style={selectedStyle}
                 onClick={()=>{
    if(!disabled){onClick()}}
  }>
  {
    icon && typeof icon === 'string' ? (
      <img src={`/images/${icon}.svg`} alt={icon} width={iconWidth} height={iconHeight}  className="Logo" draggable="false"></img>
    ) : (icon ? (icon) : null)
  }
    {text ? (
        <p style={{fontSize:textSize, marginTop: "15.5px"}}>{text}</p>
    ) : null}

  </button>
}

export default Button