import Icon from "components/icon/icon";
import "./user-control.scss"
import "./user-control.css"
import { useState } from "react";
import { useAuthentication } from "stores/authentication";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
const UserControl = () => {
  const { isAuthenticated } = useAuthentication()
  const [showMenu, setShowMenu] = useState(false)
  const nodeRef = useRef(null)
  return (
    <div className="userControlContainer" onClick={()=>setShowMenu(!showMenu)}>
      <div className={`userControl ${showMenu ? "userControlOpen" : ""}`}>
        <Icon name="single-user"/>
      </div>
      {
        <CSSTransition
          nodeRef={nodeRef}
          in={showMenu}
          timeout={100}
          
          classNames={`userControlMenu${isAuthenticated ? "Authenticated" : ""}`}
          className={`userControlMenu${isAuthenticated ? "Authenticated" : ""}`}
          unmountOnExit>
          <div ref={nodeRef} onMouseLeave={()=>setShowMenu(false)}>
            {
              isAuthenticated ? (
                <>
                  <Link to="/logout" className="mainButton mb-1">
                      Logout
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login" className="mainButton mb-1">
                      Login
                  </Link>
                  <Link to="/register" className="mainButton">
                      Register
                  </Link>
                </>
              )
            }
          </div>
        </CSSTransition>
      }
    </div>
  );
}
export default UserControl