import "./logout.scss"
import Icon from "components/icon/icon"
import Authentication from "../authentication"
import Window from "../window"
import { Link, useNavigate } from "react-router-dom"
import { useAuthentication } from "stores/authentication"
import { useState } from "react"
import { useEffect } from "react"

const Logout = () => {
  const navigate = useNavigate()
  const { logout, isAuthenticated } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(()=>{
    if(!isAuthenticated) {
      navigate("/")
    }
  },[])

  const initiateLogout = async () => {
    setIsLoading(true)
    const response = await logout()
    if(response === true) {
      setSuccess("Logged out successfuly!")
      const timeout = setTimeout(()=>{
        navigate("/")
        clearTimeout(timeout)
      }, 2000)
    } else {
      setError("Bad Logout, Refreshing!")
      const timeout = setTimeout(()=>{
        window.location.reload()
        clearTimeout(timeout)
      }, 2000)
    }
    setIsLoading(false)
  }

  return (
    <Authentication>
      <Window
        title="Logout"
        message="Logging out"
        isLoading={isLoading}
        minHeight="259px"
        error={error}
        success={success}>
        <h1 className="text-center">Logout</h1>
        <div className="logoutIconContainer">
          <Icon name="question"/>
        </div>
        <p className="text-center">Are you sure you wish to logout?</p>
        <div className="d-flex gap-2 mb-1">
          <Link className="secondaryButton" to={'..'}>Go Back</Link>
          <Link className="mainButton" onClick={initiateLogout}>Logout</Link>
        </div>
      </Window>
    </Authentication>
  )
}
export default Logout