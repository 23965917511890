import { useSession, useConversation, useCameraStream, useConversationStreams, VideoStream, useUserMediaDevices, useConversationMessages, useConversationContacts, useStreamApplyVideoProcessor, setLogLevel } from "@apirtc/react-lib";
import WindowLayout from "components/windowLayout/window-layout"
import { useRooms } from "stores/rooms";
import { MediaScreen } from "../secure/MediaScreen";
import { usePreferences } from "stores/preferences";
import { useChatroom } from "stores/chatroom";
import Chat from "./Chat";
import { useState } from "react";
import isProfane from "badwords";
import Button from "../secure/Button";
import { ExitFullScreenIcon } from "../SVGComponents/ExitFullScreenIcon";
import { FullScreenIcon } from "../SVGComponents/FullScreenIcon";
import {A11y, EffectCards, Navigation, Pagination} from 'swiper/modules';
import {Swiper} from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { ShareScreen } from "../SVGComponents/shareScreen";
import apiRTC from "@apirtc/apirtc";
import { CollapseIcon } from "../SVGComponents/CollapseIcon";
import { MuteIcon } from "../SVGComponents/muteIcon";
import { DeafenIcon } from "../SVGComponents/deafenIcon";
import { SettingsIcon } from "../SVGComponents/SettingsIcon";
import { CloseIcon } from "../SVGComponents/closeIcon";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { useMemo } from "react";
window.setApirtcReactLibLogLevel = null
// My API key
const Encrypted = () => {
  setLogLevel("error")
  const { clearRoom, current } = useRooms()
  const [options, setOptions] = useState({})
  const { session } = useSession({ apiKey: 'a7cde107bdec57bb24c312fac6d69b89' });
  const { stream: localStream } = useCameraStream(session, options);
  const { userMediaDevices } = useUserMediaDevices(session);
  const { conversation } = useConversation(session, current.id, true);
  const streamsToPublish = useMemo(
    () => (localStream ? [{ stream: localStream }] : []),
    [localStream]
  );
  const { publishedStreams, subscribedStreams } = useConversationStreams(
    conversation,
    streamsToPublish
  );
  const {isMobile, isTablet} = MediaScreen()
  const [showSettings, setShowSettings] = useState(false)
  const { setNickname: setDisplayName, nickname: displayName } = usePreferences()
  const {displayChatroom, setDisplayChatroom} = useChatroom()
  const [selectedStream, selectStream] = useState(0)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [ sharing, setSharing ] = useState(false)
  const [ shareStream, setShareStream ] = useState(null)
  const [ muted, setMuted ] = useState(false)
  const [ disableMuted, setDisableMuted ] = useState(false)
  const [collapseButtons, setCollapseButtons] = useState(true)
  const [deafened, setDeafened] = useState(false)
  const { messages, sendMessage } = useConversationMessages(conversation);
  const { contacts } = useConversationContacts(conversation)
  const [initializeAS, setInitializeAS] = useState(false)
  const [lastSpeaker, setLastSpeaker] = useState(-1)
  const [manualSelect, setManualSelect] = useState(false)
  if(session) {
    session.setUserData({nickname: displayName})
    if(!initializeAS && conversation) {
      const ua = session.getUserAgent()
      ua.enableCallStatsMonitoring(true, { interval: 500 });
      ua.enableActiveSpeakerDetecting(true, { threshold: 0.4 });
      conversation.on("audioAmplitude", (amplitudeInfo) => {
        if(amplitudeInfo.callId !== null) {
          if(amplitudeInfo.isSpeaking && amplitudeInfo.amplitude > 90) {
            setLastSpeaker(amplitudeInfo.callId)
          }
        }
      })
      setInitializeAS(true)
    }
  }
  if(!manualSelect && contacts && contacts.length > 0 && lastSpeaker !== undefined && lastSpeaker !== -1) {
    if(subscribedStreams) {
      const activeStream = subscribedStreams.find(s=>{
        return s.callId === lastSpeaker
      })
      if(activeStream) {
        const contactIndex = contacts.findIndex(c=> { return c.getId() === activeStream.contact.getId()})
        if(contactIndex !== -1) {
          setLastSpeaker(-1)
          selectStream(contactIndex+1)
        }
      }
    }
  }
  const toggleSettings = () => {
    setShowSettings(!showSettings)
  }
  const audioInputList = Object.keys(userMediaDevices.audioinput).filter(device=>{
    return device !== "Communications" && device !== "default"
  })
  const videoInputList = Object.keys(userMediaDevices.videoinput).filter(device=>{
    return device !== "Communications" && device !== "default"
  })
  let audioDevices = []
  audioInputList.forEach(identifier => {
    const device = userMediaDevices.audioinput[identifier]
    audioDevices.push({
      label: device.label,
      value: device.id
    })
  })
  let videoDevices = []
  videoInputList.forEach(identifier => {
    const device = userMediaDevices.videoinput[identifier]
    videoDevices.push({
      label: device.label,
      value: device.id
    })
  })
  const changeMicrophone = async (value) => {
    if(value.label === "No Microphone") {
      localStream.disableAudio()
      setDisableMuted(true)
    } else {
      setDisableMuted(false)
      localStream.enableAudio()
      setMuted(false)
      const settings = await localStream.getSettings()
      const createStreamOptions = {
        audioInputId: value.value,
        videoInputId: settings.video.deviceId
      }
      setOptions(createStreamOptions)
    }
  }

  const changeCamera = async (value) => {
    if(value.label === "No Camera") {
      localStream.disableVideo()
    } else {
      localStream.enableVideo()
      setMuted(false)
      const settings = await localStream.getSettings()
      const createStreamOptions = {
        audioInputId: settings.audio.deviceId,
        videoInputId: value.value
      }
      setOptions(createStreamOptions)
    }
  }

  const toggleScreenSharing = async () => {
    if(!sharing) {
      const displayMediaStreamConstraints = {
        video: {
            cursor: "always"
        },
        audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100
        }
      }
      const shareScreenStream = await apiRTC.Stream.createDisplayMediaStream(displayMediaStreamConstraints, false)
      const localAudioData = localStream.data.getAudioTracks()[0]
      if(localAudioData) {
        shareScreenStream.data.addTrack(localAudioData.clone())
      }
      shareScreenStream.on('stopped', ()=>{
        conversation.unpublish(shareScreenStream)
        conversation.publish(localStream)
        setShareStream(null)
        setSharing(false)
      })
      const success = shareScreenStream !== undefined
      if(success) {
        conversation.publish(shareScreenStream)
        conversation.unpublish(localStream)
        setShareStream(shareScreenStream)
        setSharing(true)
      }
    } else {
        conversation.publish(localStream)
        conversation.unpublish(shareStream)
        setSharing(false)
        shareStream.release()
        setShareStream(null)
    }
  }
  const toggleMute = async () => {
    if(muted) {
      localStream.enableAudio()
      if(sharing && shareStream) {
        shareStream.enableAudio()
      }
    } else {
      localStream.disableAudio()
      if(sharing && shareStream) {
        shareStream.disableAudio()
      }
    }
    setMuted(!muted)
  }
  const toggleDeafened = async () => {
    setDeafened(!deafened)
  }

  useEffect(()=>{
    return () => {
      clearRoom()
      setTimeout(()=>{window.location.reload(), 100})
    }
  },[clearRoom])
  useEffect(()=>{
    const interval = setTimeout(()=>{
      window.location = "/maxtime"
    }, 1000*10*60*30)
    return () => {
      clearTimeout(interval)
    }
  }, [])
  const formattedStreams = [{}]
  contacts.forEach(contact => {
    if(contact.userData && contact.userData.nickname) {
      const hasStream = subscribedStreams.find(subscribedStream => {
        if(subscribedStream.contact && subscribedStream.contact.userData && subscribedStream.contact.userData.nickname) {
          return subscribedStream.contact.userData.nickname === contact.userData.nickname
        }
      })
      if(hasStream) {
        formattedStreams.push(hasStream)
      } else {
        formattedStreams.push({
          hasAudio: ()=>false,
          hasVideo: ()=>false,
          contact: contact
        })
      }
    }
  })
  return (
    <WindowLayout>
      <div className="chatroom" id="mememe-container">
        <div className={`containerChatRoom ${displayChatroom ? "chatActive":""}`}>
            <div className="Video">
              <div className="SelectedParticipant">
                { // Us
                  selectedStream === 0 && (localStream && !sharing && localStream.hasVideo()) ? (
                    <>
                      <VideoStream stream={localStream} muted={true}></VideoStream>
                      <span className="Name" style={{textTransform: "capitalize"}}>{displayName}</span>
                    </>
                  ) : selectedStream === 0 && (sharing && shareStream && shareStream.hasVideo()) ? (
                    <>
                      <VideoStream stream={shareStream} muted={true}></VideoStream>
                      <span className="Name" style={{textTransform: "capitalize"}}>{displayName}</span>
                    </>
                  ) : selectedStream === 0 ? (
                    <>
                      <div className="user">
                        <img src="/images/notLoggedIn.svg" width={128}/>
                      </div>
                      <span className="Name" style={{textTransform: "capitalize"}}>{displayName}</span>
                    </>
                  ) : /* Them*/ formattedStreams[selectedStream] && formattedStreams[selectedStream].hasVideo() ? (
                    <>
                      <VideoStream stream={formattedStreams[selectedStream]} muted={true}></VideoStream>
                      <span className="Name" style={{textTransform: "capitalize"}}>{formattedStreams[selectedStream].contact.userData.nickname}</span>
                    </>
                  ) : (
                    formattedStreams[selectedStream] ? (
                      <div>
                        <div className="user">
                          <img src="/images/notLoggedIn.svg" width={128}/>
                        </div>
                        <span className="Name" style={{textTransform: "capitalize"}}>{formattedStreams[selectedStream].contact.userData.nickname}</span>
                      </div>
                    ) : (
                      <div>
                        <div className="user">
                          <img src="/images/notLoggedIn.svg" width={128}/>
                        </div>
                        <span className="Name" style={{textOverflow: "unset", fontSize: "13px"}}>User Left</span>
                      </div>
                    )
                  )
                  // selectedStream === 0 ? (
                  //   <>
                  //     { localStream && !sharing && <VideoStream stream={localStream} muted={true}></VideoStream> }
                  //     { shareStream && sharing && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                  //   </>
                  // ) : (
                  //   <>
                  //     { subscribedStreams[selectedStream] ? <VideoStream stream={subscribedStreams[selectedStream]} muted={true}></VideoStream> : localStream && <VideoStream stream={localStream} muted={true}></VideoStream> }
                  //   </>
                  // )
                }
              </div>
            </div>
            <div className="Chat" style={displayChatroom ? {}:{display:'none'}}>
              <Chat sendMessage={sendMessage} messages={messages}/>
            </div>
            <div className="Guests">
            <div className={`SmallVideosContainer ${isMobile && subscribedStreams.length > 0 ? 'withCarousel' : isTablet && subscribedStreams.length > 1 ? 'withCarousel': !isTablet && subscribedStreams.length > 2 ? 'withCarousel' : '' }`}>
              {isMobile && subscribedStreams.length > 0 ? (
                  <Swiper
                      modules={[Navigation, Pagination, A11y, EffectCards]}
                      spaceBetween={15}
                      slidesPerView={2}
                      navigation
                      pagination={{ clickable: true }}
                  >
                    <SwiperSlide key={0} className="individual-card-container">
                      <div className="Participant currentUserScreen" onClick={()=>selectStream(0)}>
                        <div className="VideoContainer" style={{position: "relative"}}>
                        { sharing && shareStream && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                            { localStream && !sharing && localStream.hasVideo() && <VideoStream stream={localStream} muted={true}></VideoStream> }
                            { !sharing && !shareStream && (!localStream || (localStream && !localStream.hasVideo())) && (
                              <div className="user">
                                <img src="/images/notLoggedIn.svg" width={128}/>
                                <p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>
                              </div>
                            ) }
                            { localStream || shareStream ? (<p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>): null }
                        </div>
                      </div>
                    </SwiperSlide>
                    {
                      contacts.map((item,index)=>{
                        const contactStream = subscribedStreams.find(cStream=>{
                          if(cStream.contact && cStream.contact.userData && cStream.contact.userData.nickname) {
                            return cStream.contact.userData.nickname === item.userData.nickname
                          }
                          return false
                        })
                        return(
                          <SwiperSlide className="individual-card-container">
                            <div className="Participant" key={index} onClick={()=>selectStream(index+1)}>
                              <div className="VideoContainer" style={{position: "relative"}}>
                                {
                                  contactStream && contactStream.hasVideo() ? (
                                    <>
                                      <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                      <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                    </>
                                  ) : contactStream && contactStream.hasAudio() ? (

                                    <div className="user">
                                      <div style={{visibility: "hidden", maxHeight: "0px", maxWidth: "0px"}}>
                                        <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                      </div>
                                      <img src="/images/notLoggedIn.svg" width={128}/>
                                      <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                    </div>
                                  ):(
                                    <div className="user">
                                      <img src="/images/notLoggedIn.svg" width={128}/>
                                      <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
              ) : (
                  isTablet && subscribedStreams.length > 1 ? (
                      <Swiper
                          modules={[Navigation, Pagination, A11y, EffectCards]}
                          spaceBetween={15}
                          slidesPerView={!displayChatroom ? 2 : 3}
                          navigation
                          pagination={{ clickable: true }}
                      >
                        <SwiperSlide key={0} className="individual-card-container">
                          <div className="Participant currentUserScreen" onClick={()=>selectStream(0)}>
                            <div className="VideoContainer" style={{position: "relative"}}>
                            { sharing && shareStream && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                            { localStream && !sharing && localStream.hasVideo() && <VideoStream stream={localStream} muted={true}></VideoStream> }
                            { !sharing && !shareStream && (!localStream || (localStream && !localStream.hasVideo())) && (
                              <div className="user">
                                <img src="/images/notLoggedIn.svg" width={128}/>
                                <p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>
                              </div>
                            ) }
                            { localStream || shareStream ? (<p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>): null }
                            </div>
                          </div>
                        </SwiperSlide>
                        {
                          contacts.map((item,index)=>{
                            const contactStream = subscribedStreams.find(cStream=>{
                              if(cStream.contact && cStream.contact.userData && cStream.contact.userData.nickname) {
                                return cStream.contact.userData.nickname === item.userData.nickname
                              }
                              return false
                            })
                            return(
                              <SwiperSlide className="individual-card-container">
                                <div className="Participant" key={index} onClick={()=>selectStream(index+1)}>
                                  <div className="VideoContainer" style={{position: "relative"}}>
                                    {
                                      contactStream && contactStream.hasVideo() ? (
                                        <>
                                          <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname} BBB</p>
                                        </>
                                      ) : contactStream && contactStream.hasAudio() ? (

                                        <div className="user">
                                          <div style={{visibility: "hidden", maxHeight: "0px", maxWidth: "0px"}}>
                                            <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                          </div>
                                          <img src="/images/notLoggedIn.svg" width={128}/>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname} BBB</p>
                                        </div>
                                      ):(
                                        <div className="user">
                                          <img src="/images/notLoggedIn.svg" width={128}/>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname} BBB</p>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                  ) : !isTablet && subscribedStreams.length > 2 ? (
                      <Swiper
                          modules={[Navigation, Pagination, A11y, EffectCards]}
                          spaceBetween={15}
                          slidesPerView={!displayChatroom ? 3 : 4}
                          navigation
                          pagination={{ clickable: true }}
                      >
                        <SwiperSlide key={0} className="individual-card-container">
                          <div className="Participant currentUserScreen" onClick={()=>selectStream(0)}>
                            <div className="VideoContainer" style={{position: "relative"}}>
                              { sharing && shareStream && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                              { localStream && !sharing && localStream.hasVideo() && <VideoStream stream={localStream} muted={true}></VideoStream> }
                              { !sharing && !shareStream && (!localStream || (localStream && !localStream.hasVideo())) && (
                                <div className="user">
                                  <img src="/images/notLoggedIn.svg" width={128}/>
                                  <p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>
                                </div>
                              ) }
                              { localStream || shareStream ? (<p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>): null }
                            </div>
                          </div>
                        </SwiperSlide>
                        {
                          contacts.map((item,index)=>{
                            const contactStream = subscribedStreams.find(cStream=>{
                              if(cStream.contact && cStream.contact.userData && cStream.contact.userData.nickname) {
                                return cStream.contact.userData.nickname === item.userData.nickname
                              }
                              return false
                            })
                            return(
                              <SwiperSlide className="individual-card-container">
                                <div className="Participant" key={index} onClick={()=>selectStream(index+1)}>
                                  <div className="VideoContainer" style={{position: "relative"}}>
                                    {
                                      contactStream && contactStream.hasVideo() ? (
                                        <>
                                          <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                        </>
                                      ) : contactStream && contactStream.hasAudio() ? (

                                        <div className="user">
                                          <div style={{visibility: "hidden", maxHeight: "0px", maxWidth: "0px"}}>
                                            <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                          </div>
                                          <img src="/images/notLoggedIn.svg" width={128}/>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                        </div>
                                      ):(
                                        <div className="user">
                                          <img src="/images/notLoggedIn.svg" width={128}/>
                                          <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                  ) : (
                      <>
                        <div className="Participant currentUserScreen" onClick={()=>selectStream(0)}>
                          <div className="VideoContainer" style={{position: "relative"}}>
                            { sharing && shareStream && <VideoStream stream={shareStream} muted={true}></VideoStream> }
                            { localStream && !sharing && localStream.hasVideo() && <VideoStream stream={localStream} muted={true}></VideoStream> }
                            { !sharing && !shareStream && (!localStream || (localStream && !localStream.hasVideo())) && (
                              <div className="user">
                                <img src="/images/notLoggedIn.svg" width={128}/>
                                <p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>
                              </div>
                            ) }
                            { localStream || shareStream ? (<p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>): null }
                          </div>
                        </div>
                        {
                          contacts.map((item,index)=>{
                              const contactStream = subscribedStreams.find(cStream=>{
                                if(cStream.contact && cStream.contact.userData && cStream.contact.userData.nickname) {
                                  return cStream.contact.userData.nickname === item.userData.nickname
                                }
                                return false
                              })
                              return(
                                <div className="Participant" key={index} onClick={()=>selectStream(index+1)}>
                                  <SwiperSlide className="individual-card-container">
                                    <div className="VideoContainer" style={{position: "relative"}}>
                                      {
                                        contactStream && contactStream.hasVideo() ? (
                                          <>
                                            <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                            <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                          </>
                                        ) : contactStream && contactStream.hasAudio() ? (

                                          <div className="user">
                                            <div style={{visibility: "hidden", maxHeight: "0px", maxWidth: "0px"}}>
                                              <VideoStream stream={contactStream} muted={deafened} key={index}></VideoStream>
                                            </div>
                                            <img src="/images/notLoggedIn.svg" width={128}/>
                                            <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                          </div>
                                        ):(
                                          <div className="user">
                                            <img src="/images/notLoggedIn.svg" width={128}/>
                                            <p className="Name" style={{textTransform: "capitalize"}}>{ item.userData && item.userData.nickname}</p>
                                          </div>
                                        )
                                      }
                                    </div>
                                  </SwiperSlide>
                                </div>
                              )
                          })
                        }
                      </>
                  )
              )}
            </div>
            </div>
            <div className="Options">
              <div className="meetingSettings">
                {isMobile &&
                  <div
                    className={`container-icon-collapse`}
                    style={{
                      padding:collapseButtons ? '0' :'10px 0 0 0',
                      transform:collapseButtons ? 'rotate(0deg)' :'rotate(180deg)'
                    }}
                    onClick={()=> {
                      setCollapseButtons(!collapseButtons)
                      setDisplayChatroom(false)
                    }}>
                    <CollapseIcon fill={'#000'} />
                  </div>
                }
                <div className="BigButtonsContainer" style={{
                    transition: 'height ease .2s',
                    overflow:'hidden',
                    height: isMobile ? collapseButtons ? '0svh' : '24svh' : ''
                }}>
                <Button
                    textSize={"17px"}
                    style={ !isMobile && !isTablet ? {height: "40px"} : {visibility: "hidden", height: "0px"}}
                    text={sharing ? "Stop Sharing" : "Share Screen"}
                    icon={<ShareScreen fill={'#fff'}/>}
                    buttonStyle="shareScreen"
                    onClick={toggleScreenSharing}
                />
                <Button
                  text={muted ? "Unmute" : "Mute"}
                  textSize={"17px"}
                  style={{marginLeft: 0, height: '40px'}}
                  icon={<MuteIcon fill={'#000'}/>}
                  buttonStyle="control"
                  onClick={()=>{
                    if(!disableMuted) {
                      toggleMute()
                    }
                  }}
                  disabled={disableMuted}
                />
                <Button
                  text={deafened ? "Enable All Sounds" : "Disable All Sounds"}
                  icon={<DeafenIcon fill={'#000'}/>}
                  textSize={"17px"}
                  style={{marginLeft: 0, height: '40px'}}
                  buttonStyle="control"
                  onClick={toggleDeafened}
                />
                <Button
                  icon={<SettingsIcon fill={'#fff'}/>}
                  textSize={"17px"}
                  text={isMobile && 'Settings'}
                  style={{
                      marginLeft: 0,
                      width: !isMobile && '150px',
                      height: '40px',
                      border: '1px solid transparent'
                  }}
                  buttonStyle="shareScreen"
                  className='settings'
                  onClick={
                  ()=>{
                    toggleSettings(userMediaDevices, localStream, conversation)
                  }
                }
                />
                </div>
              </div>
            </div>
        </div>
        {/* <h2>Local Streams</h2>
        { localStream && <VideoStream stream={localStream} muted={true}></VideoStream> }
        </div>
        <div id='remote-streams-container'>
        <h2>Remote Streams</h2>
        { 
          subscribedStreams.map( (stream, index) =>{
            return (<VideoStream stream={stream} muted={false} key={index}></VideoStream>)
          })
        } */}
        {
          showSettings ? (
            <div className="modal-container" style={{backgroundColor: "transparent"}}>
              <div className="modal-content">
                <Button buttonStyle={'transparentBlack'} onClick={()=>toggleSettings()} className='close-modal' icon={<CloseIcon fill={'#000'} />}/>
                <h2>Devices</h2>
                <ReactSelect
                    placeholder={audioDevices.length ? "Audio Input Device" : 'No microphones detected'}
                    isDisabled={!audioDevices.length}
                    isSearchable={false}
                    className={'react-select'}
                    options={[...audioDevices, {label: "No Microphone", value: ""}]}
                    onChange={(value)=>changeMicrophone(value)}
                />
                <ReactSelect
                  placeholder={videoDevices.length ? "Video Input Device" : 'No Camera detected'}
                  isDisabled={!videoDevices.length}
                  isSearchable={false}
                  className={'react-select'}
                  options={[...videoDevices, {label: "No Camera", value: ""}]}
                  onChange={(value)=>changeCamera(value)}
                />
              </div>
            </div>
          ) : null
        }
      </div>
    </WindowLayout>
  )
}
export default Encrypted