import Icon from "components/icon/icon"
import { useNavigate } from "react-router-dom"
import { useRooms } from "stores/rooms"

const LeaveRoom = () => {
  const navigate = useNavigate()
  const { clearRoom } = useRooms()
  const exitRoom = () => {
    navigate("/")
    clearRoom()
  }
  return (
    <div className="controlButtonDanger" onClick={exitRoom}>
      <Icon name="exit-meeting" size={20} className="onlyMobile"/>
      <p className="onlyDesktop">Leave Room</p>
    </div>
  )
}

export default LeaveRoom