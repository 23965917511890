import { useEffect } from "react"
import { useState } from "react"
import dayjs from "dayjs";

const Message = ({sender, message, date}) => {
  const [seen, setSeen] = useState(false)
  const see = () => {
    if(!seen) {
      setSeen(true)
    }
  }
  useEffect(()=>{
    const interval = setTimeout(()=>{
      see()
    }, 3000)
    return ()=>{
      clearTimeout(interval)
    }
  }, [])
  return (
    <div className={`message ${!seen ? 'new' : ''}`} onMouseEnter={see}>
      <div>
        <img src="/images/user.svg" className="Logo" draggable="false"></img>
      </div>
      <div className='messageContent'>
        <div className='firstLineMessage'>
          <h4 className='messageUserName' style={{fontWeight:600, textTransform: "capitalize"}}>{sender}</h4>
          <span className='messageDate' style={{color:'#838383'}}>{date}</span>
        </div>

        <span className='messageText'>{message}</span>
      </div>
    </div>
  )
}
export default Message