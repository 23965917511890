import WindowLayout from "components/windowLayout/window-layout"
import { useRooms } from "stores/rooms";
import AttemptJoinRoom from "../attempt-room-join";
import Secure from "./secure/secure";
import Encrypted from "./encrypted/Chatroom";

const Chatroom = () => {
  const { current } = useRooms()
  const isInRoom = !!current
  const isSecure = current && current.secure
  return (
     !isInRoom ? <AttemptJoinRoom/>
    : isSecure ? <Secure/>
               : <Encrypted/>
  )
}

export default Chatroom