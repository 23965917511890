import "react-phone-input-2/lib/style.css";
import Input from "components/input/input"
import Authentication from "../authentication"
import Window from "../window"
import { useState } from "react"
import Checkbox from "components/checkbox"
import { useAuthentication } from "stores/authentication";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate()
  const { login, isAuthenticated } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [])
  const resetState = () => {
    setIsLoading(false)
    setError("")
    setSuccess("")
    setEmail("")
    setPassword("")
    setTermsAgreed(false)
  }
  const attemptLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const result = await login({
      email: email.toLowerCase(),
      password
    })
    if (result === true) {
      resetState()
      setSuccess("Logged in successfully!")
      setTimeout(() => {
        navigate(`/`)
      }, 2000)
    } else if (result === "NOT_VERIFIED") {
      setError("Email not verified!")
      setIsLoading(false)
      setTimeout(() => {
        navigate(`/verify?email=${email}`)
      }, 2000)
    } else {
      setError("Bad email/password combination.")
      setIsLoading(false)
      setTimeout(() => {
        setError("")
      }, 2000)
    }
  }
  return (
    <Authentication>
      <Window
        title="Login"
        message="Logging in"
        isLoading={isLoading}
        minHeight="296px"
        error={error}
        success={success}>
        <h1 className="text-center">Login</h1>
        <form onSubmit={attemptLogin} style={{marginBottom: 0}}>
          <Input
            name="email"
            label="Email"
            type="email"
            value={email}
            autoComplete="username"
            required
            onChange={(e) => setEmail(e.target.value)} />
          <Input
            name="password"
            label="Password"
            autoComplete="new-password"
            type="password"
            minLength="6"
            maxLength="32"
            value={password}
            required
            style={{marginBottom: "6px"}}
            onChange={(e) => setPassword(e.target.value)} />
          <Link to="/recovery">Forgot password?</Link>
          <div style={{
            height: "6px",
            width: "100%",
          }}/>
          <Checkbox
            value={termsAgreed}
            onClick={() => setTermsAgreed(!termsAgreed)}
            required
            label="I agree to the"
            linkLabel="Booom Disclaimer"
            linkTo="/disclaimer" />
          <input
            className={
              `MainButton ${email && password ?
                "" : "disabled"
              }`}
            type="submit"
            value="Login"
            required />
          <p style={{margin: 0, marginTop: "2px", padding: 0}}>Don't have an account? <Link to="/register">Register here</Link></p>
        </form>
      </Window>
    </Authentication>
  )
}
export default Login