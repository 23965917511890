export const CollapseIcon = ({fill}) => {
    return(
        <svg fill={fill} width="32px" height="32px" viewBox="0 0 36 36" version="1.1"
             preserveAspectRatio="xMidYMid meet" >
            <path className="clr-i-outline clr-i-outline-path-1"
                  d="M29,19.41a1,1,0,0,1-.71-.29L18,8.83,7.71,19.12a1,1,0,0,1-1.41-1.41L18,6,29.71,17.71A1,1,0,0,1,29,19.41Z"></path>
            <path className="clr-i-outline clr-i-outline-path-2"
                  d="M29,30.41a1,1,0,0,1-.71-.29L18,19.83,7.71,30.12a1,1,0,0,1-1.41-1.41L18,17,29.71,28.71A1,1,0,0,1,29,30.41Z"></path>
        </svg>
    )
}