import "./disclaimer.scss"
import WindowLayout from "components/windowLayout/window-layout"
import { Link } from "react-router-dom"

const Disclaimer = () => {
  return (
    <WindowLayout>
      <div className="Disclaimer">
        <h1>Disclaimer and Terms of Use for Booom.global Voice and Chat Application</h1>
        <br />
        <h2>1. Acceptance of Terms</h2>
        By accessing or using the "booom.global" voice and chat application Booom, you agree to be bound by the terms and conditions set forth in this disclaimer. If you do not agree to these terms, please refrain from using this Application.
        <h2>2. No Liability for Misuse</h2>
        booom.global provides this Application for communication purposes only. We strictly prohibit the use of this Application for any illegal activities, including but not limited to fraud, intellectual property infringement, harassment, and distribution of illegal content. booom.global is not responsible for the actions of its users or for any content transmitted through this Application. Users are solely responsible for their conduct and the content they share via the Application.
        <h2>3. Reporting Obligations</h2>
        <p>Users are encouraged to report any suspicious or illegal activities conducted through the Application. booom.global will cooperate with law enforcement authorities in investigating such activities, as required by law.</p>
        <h2>4. Beta Phase and Software Development</h2>
        <p>Please note that this Application is currently in its beta phase. This means that the software is still under development and may contain bugs or errors. We do not guarantee uninterrupted or error-free operation of the Application during this beta phase.</p>
        <Link>We greatly appreciate your participation in this phase and encourage you to report any bugs, glitches, or operational issues you encounter. Your feedback is invaluable in helping us improve the Application.</Link>
        <br />
        <br />
        <h2>5. Limitation of Liability</h2>
        <p>In no event shall booom.global be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if booom.global has been advised of the possibility of such damages), resulting from the use or the inability to use the Application.</p>
        <h2>6. Modifications to Disclaimer</h2>
        <p>booom.global reserves the right to modify this disclaimer at any time. Your continued use of the Application following the posting of any changes to this disclaimer constitutes acceptance of those changes.</p>
        <h2>7. Contact Information</h2>
        For any questions or concerns regarding this disclaimer or the Application, please contact us.
      </div>
    </WindowLayout>
  )
}

export default Disclaimer