import "./layout.scss"
import Footer from "./footer"
import Header from "./header"
import Content from "./content"
import { Outlet } from "react-router-dom"

const Layout = ({children}) => {
  return (
    <div className="layout">
      <Header/>
      <Content>
        <Outlet>
          {children}
        </Outlet>
      </Content>
      <Footer/>
    </div>
  )
}
export default Layout