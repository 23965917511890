import { BrowserRouter, Route, Routes } from "react-router-dom"
import Layout from "./layout/layout"
import Register from "./pages/Authentication/Register/register"
import Verify from "./pages/Authentication/Verify/verify"
import NotFound from "./pages/notFound"
import Login from "./pages/Authentication/Login/login"
import Recovery from "./pages/Authentication/Recovery/recovery"
import Recover from "./pages/Authentication/Recover/recover"
import WindowLayout from "./windowLayout/window-layout"
import Logout from "./pages/Authentication/Logout/logout"
import Rooms from "./pages/Rooms/rooms"
import Disclaimer from "./pages/Disclaimer/disclaimer"
import AttemptJoinRoom from "./pages/Rooms/attempt-room-join"
import CreateRoomOptions from "./pages/Rooms/create-room-options"
import Chatroom from "./pages/Rooms/chatroom/chatroom-selector"
import MaxTime from "./pages/maxTime"

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Rooms/>}/>
        <Route path="disclaimer" element={<Disclaimer/>}/>
        <Route path="join" element={<AttemptJoinRoom/>}/>
        <Route path="createRoom" element={<CreateRoomOptions/>}/>
        <Route path="chatroom" element={<Chatroom/>}/>
        <Route path="register" element={<Register/>}/>
        <Route path="login" element={<Login/>}/>
        <Route path="verify" element={<Verify/>}/>
        <Route path="recovery" element={<Recovery/>}/>
        <Route path="recover" element={<Recover/>}/>
        <Route path="logout" element={<Logout/>}/>
        <Route path="maxtime" element={<MaxTime/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}

export default Pages