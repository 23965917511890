import "./window-layout.scss"
import TitleBar from "./title-bar/title-bar"
import { ToastContainer } from "react-toastify"
import ToastHandler from "../Toast/ToastHandler"

export const WindowLayout = ({children}) => {
  return (
      <div className="windowLayout w-100 mx-auto rounded-4 text-dark bg-white">
        <div className="fixcenter">
          <TitleBar inChatroom={children.isInChatroom}/>
          <div className="px-4 windowLayoutContent">
            {children}
          </div>
        </div>
        <ToastContainer/>
        <ToastHandler/>
      </div>
  )
}

export default WindowLayout