import React, { useState } from "react";
import './CheckBoxComponent.scss'
import { Text} from "react";

const CheckBoxComponent = ({ label,link,linkText, isChecked: initialChecked, onCheckChange }) => {
    const [isChecked, setIsChecked] = useState(initialChecked);

    const handleCheckboxChange = () => {
        const updatedChecked = !isChecked;
        setIsChecked(updatedChecked);
        onCheckChange(updatedChecked);
    };
        return (
            <label className="container">
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                <span className="checkmark"></span>
                <p>{label}{link && <a href={link} style={{color: '#4423b6'}}>{linkText}</a>}</p>

            </label>
        );
};

export default CheckBoxComponent;
