import { shallow } from "zustand/shallow"
import usePeers from "stores/peers"
import Participant from "./Participant"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards'
import {Swiper} from "swiper/react";
import {A11y, EffectCards, Navigation, Pagination} from 'swiper/modules';
import {SwiperSlide} from "swiper/react";
import {MediaScreen} from "./MediaScreen";
const Participants = ({chatIsActive}) => {
  const {peers, selectPeer} = usePeers()

  const {isMobile,isTablet} = MediaScreen()
  const list =[]
  Object.keys(peers).forEach(peerId=>{
    list.push(peerId)
  })
  return (
    <div className={`SmallVideosContainer ${isMobile && list.length > 1 ? 'withCarousel' : isTablet && list.length > 2 ? 'withCarousel': !isTablet && list.length > 3 ? 'withCarousel' : '' }`}>
      {isMobile && list.length > 1 ? (
          <Swiper
              modules={[Navigation, Pagination, A11y, EffectCards]}
              spaceBetween={15}
              slidesPerView={2}
              navigation
              pagination={{ clickable: true }}
          >
            <SwiperSlide key={0} className="individual-card-container">
              <Participant key="UniqueBigScreen" onClick={()=>{ console.log("YTA");selectPeer(null) }}/>
            </SwiperSlide>
            {list.map((item,index)=>{
              return(
                  <SwiperSlide key={index} className="individual-card-container">
                    <Participant key={`user${item}`} peer={peers[item]} onClick={()=>{ selectPeer(item) }}/>
                  </SwiperSlide>
              )
            })}
          </Swiper>
      ) : (
          isTablet && list.length > 2 ? (
              <Swiper
                  modules={[Navigation, Pagination, A11y, EffectCards]}
                  spaceBetween={15}
                  slidesPerView={chatIsActive ? 2 : 3}
                  navigation
                  pagination={{ clickable: true }}
              >
                <SwiperSlide key={0} className="individual-card-container">
                  <Participant key="UniqueBigScreen" onClick={()=>{ console.log("YTA");selectPeer(null) }}/>
                </SwiperSlide>
                {list.map((item,index)=>{
                  return(
                      <SwiperSlide key={index} className="individual-card-container">
                        <Participant key={`user${item}`} peer={peers[item]} onClick={()=>{ selectPeer(item) }}/>
                      </SwiperSlide>
                  )
                })}
              </Swiper>
          ) : !isTablet && list.length > 3 ? (
              <Swiper
                  modules={[Navigation, Pagination, A11y, EffectCards]}
                  spaceBetween={15}
                  slidesPerView={chatIsActive ? 3 : 4}
                  navigation
                  pagination={{ clickable: true }}
              >
                <SwiperSlide key={0} className="individual-card-container">
                  <Participant key="UniqueBigScreen" onClick={()=>{ console.log("YTA");selectPeer(null) }}/>
                </SwiperSlide>
                {list.map((item,index)=>{
                  return(
                      <SwiperSlide key={index} className="individual-card-container">
                        <Participant key={`user${item}`} peer={peers[item]} onClick={()=>{ selectPeer(item) }}/>
                      </SwiperSlide>
                  )
                })}
              </Swiper>
          ) : (
              <>
                <Participant key="UniqueBigScreen" onClick={()=>{ console.log("YTA");selectPeer(null) }}/>
                {list.map((item)=>{
                  return <Participant key={`user${item}`} peer={peers[item]} onClick={()=>{ selectPeer(item) }}/>
                })}
              </>
          )
      )}

    </div>
  )
}
export default Participants

/*
<>
<Participant key="UniqueBigScreen" onClick={()=>{ console.log("YTA"),selectPeer(null) }}/>
{list.map((item)=>{
  return <Participant key={`user${item}`} peer={peers[item]} onClick={()=>{ selectPeer(item) }}/>
})}
</>*/
