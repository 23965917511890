import { Link } from "react-router-dom"
import Icon from "./icon/icon"

const Checkbox = ({value, onClick, label="", linkLabel, linkTo, required}) => {
  return (
    <div className="checkBoxContainer">
      <div className={`checkBox ${value && "checked"}`} onClick={onClick}>
        <input
          type="checkbox"
          style={{opacity: 0, width: "1px", height: "1px"}}
          required={required}
          checked={value}
          onClick={()=>{}}
          onChange={()=>{}}/>
        {value && <Icon name="check"/>}
      </div>
      <p className="checkBoxLabel">{label}</p>
      <Link to={linkTo}>{linkLabel}</Link>
    </div>
  ) 
}
export default Checkbox