import "./rooms.scss"
import Checkbox from "components/checkbox"
import Icon from "components/icon/icon"
import Input from "components/input/input"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const CreateRoom = (props) => {
  const navigate = useNavigate()
  const [roomName, setGroupName] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)

  const proceed = (e) => {
    e.preventDefault()
    navigate(`/createRoom?roomName=${roomName}`)
  }
  return (
    <div className={`${props.className} d-flex h-100 flex-column justify-content-center align-items-center`}>
      <Icon name="meeting-room" size="100"/>
      <h1 className="roomsTitle">Create a meeting</h1>
      <br></br>
      <br></br>
      <form onSubmit={proceed} className="w-100">
        <Input
          name="create-room"
          label="Meeting name"
          type="text"
          value={roomName}
          autoComplete="none"
          minLength="3"
          required
          onChange={(e) => setGroupName(e.target.value)} />
        <Checkbox
          value={termsAgreed}
          onClick={() => setTermsAgreed(!termsAgreed)}
          required
          label="I agree to the"
          linkLabel="Booom Disclaimer"
          linkTo="/disclaimer" />
          <br />
        <input
          className={
            `SecondaryButton ${termsAgreed && roomName && roomName.length >= 3 ?
              "" : "disabled"
            }`}
          type="submit"
          value="Create Meeting"
          required />
      </form>
    </div>
  )
}
export default CreateRoom