import UserVideo from "./UserVideo"
import useDevices from "stores/devices"
import OwnStream from "./OwnStream"
import { useEffect } from "react"
import { useState } from "react"
import { usePreferences } from "stores/preferences"
const Participant = ({peer, peerId, onClick, isSharing}) => {
  const {nickname: displayName} = usePreferences()

  const [updateTrigger, setUpdateTrigger] = useState(false)
  if (peer) peer.triggerParticipant = () => { setUpdateTrigger(!updateTrigger) }

  return (
    peer ? (
      <div className="Participant" onClick={onClick ? onClick : () => {}}>
        <div className="VideoContainer" style={{position: "relative"}}>
          <UserVideo peer={peer}/>
            <p className="Name" style={{textTransform: "capitalize"}}>{peer.nickname}</p>

        </div>
      </div>
    ) : (
      <div className="Participant currentUserScreen" onClick={onClick ? onClick : () => {}}>
        <div className="VideoContainer" style={{position: "relative"}}>
          <OwnStream isSharing={isSharing}/>
            <p className="Name" style={{textTransform: "capitalize"}}>{displayName}</p>

        </div>

      </div>
    )
  )
}
export default Participant